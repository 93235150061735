import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expellers-processes',
  templateUrl: './expellers-processes.component.html',
  styles: []
})
export class ExpellersProcessesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
