import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WeighingRoutingModule } from './weighing-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { EntryComponent } from './components/entry/entry.component';
import { EntryVehicleDetailComponent } from './components/entry/entry-vehicle-detail/entry-vehicle-detail.component';
import { EntryWeighingComponent } from './components/entry/entry-weighing/entry-weighing.component';
import { DepartureComponent } from './components/departure/departure.component';
import { DepartingVehicleDetailComponent } from './components/departure/departing-vehicle-detail/departing-vehicle-detail.component';
import { DepartureWeighingComponent } from './components/departure/departure-weighing/departure-weighing.component';
import { WeighingLoginComponent } from './components/weighing-login/weighing-login.component';
import { WeighingDetectedUserComponent } from './components/weighing-detected-user/weighing-detected-user.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [MainMenuComponent, EntryComponent, EntryVehicleDetailComponent, EntryWeighingComponent, DepartureComponent, DepartingVehicleDetailComponent, DepartureWeighingComponent, WeighingLoginComponent, WeighingDetectedUserComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    WeighingRoutingModule,
    LoginModule,
    NgxLoadingModule,
    SweetAlert2Module
  ]
})
export class WeighingModule { }
