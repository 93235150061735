import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilteringRoutingModule } from './filtering-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { AdditivesComponent } from './components/additives/additives.component';
import { RegisterAdditivesComponent } from './components/register-additives/register-additives.component';
import { ShakingComponent } from './components/shaking/shaking.component';
import { StartShakingComponent } from './components/start-shaking/start-shaking.component';
import { ConfirmStartShakingComponent } from './components/confirm-start-shaking/confirm-start-shaking.component';
import { ConfirmFinishShakingComponent } from './components/confirm-finish-shaking/confirm-finish-shaking.component';
import { FinishShakingComponent } from './components/finish-shaking/finish-shaking.component';
import { FilteringComponent } from './components/filtering/filtering.component';
import { StartFilteringComponent } from './components/start-filtering/start-filtering.component';
import { ConfirmStartFilteringComponent } from './components/confirm-start-filtering/confirm-start-filtering.component';
import { ConfirmFinishFilteringComponent } from './components/confirm-finish-filtering/confirm-finish-filtering.component';
import { FinishFilteringComponent } from './components/finish-filtering/finish-filtering.component';
import { CleaningComponent } from './components/cleaning/cleaning.component';
import { RegisterCleaningComponent } from './components/register-cleaning/register-cleaning.component';
import { ConfirmRegisterCleaningComponent } from './components/confirm-register-cleaning/confirm-register-cleaning.component';
import { AdditivesSelectPeriodComponent } from './components/additives-select-period/additives-select-period.component';
import { LoginModule } from '../login/login.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ReworkSelectMoveComponent } from './components/rework-select-move/rework-select-move.component';
import { ReworkConfirmComponent } from './components/rework-confirm/rework-confirm.component';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, AdditivesComponent, RegisterAdditivesComponent, ShakingComponent, StartShakingComponent, ConfirmStartShakingComponent, ConfirmFinishShakingComponent, FinishShakingComponent, FilteringComponent, StartFilteringComponent, ConfirmStartFilteringComponent, ConfirmFinishFilteringComponent, FinishFilteringComponent, CleaningComponent, RegisterCleaningComponent, ConfirmRegisterCleaningComponent, AdditivesSelectPeriodComponent, ReworkSelectMoveComponent, ReworkConfirmComponent ],
  imports: [
    CommonModule,
    FilteringRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class FilteringModule { }
