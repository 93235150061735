import { Component, OnInit } from '@angular/core';
import { ReactorsService } from '../../services/reactors.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-additives',
  templateUrl: './additives.component.html',
  styles: []
})
export class AdditivesComponent implements OnInit {

  reactors: any;
  data: any;
  loading = false;

  constructor(
    private reactorsService: ReactorsService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/filtro/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.reactorsService.getAllInactiveReactors().then(resp => {
      this.reactors = resp['data'] as any;
      this.loading = false;
    });
  }

  select(reactor) {
    this.data.reactor = reactor;

    this.router.navigate(['/filtro/procesos/adicion/confirmar'], {state: {data: this.data}});
  }

}
