export const environment = {
    production: true,
    apiUrl: 'https://hirina.inver.space/',
    // apiUrl: 'http://localhost/',
    scanTypes: {
        VEHICLE: 'VEHICLE',
        BRANCH: 'BRANCH',
    },
    extras: {
        months: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
    }
};
