import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { AdditivesComponent } from './components/additives/additives.component';
import { RegisterAdditivesComponent } from './components/register-additives/register-additives.component';
import { ShakingComponent } from './components/shaking/shaking.component';
import { StartShakingComponent } from './components/start-shaking/start-shaking.component';
import { ConfirmStartShakingComponent } from './components/confirm-start-shaking/confirm-start-shaking.component';
import { FinishShakingComponent } from './components/finish-shaking/finish-shaking.component';
import { ConfirmFinishShakingComponent } from './components/confirm-finish-shaking/confirm-finish-shaking.component';
import { FilteringComponent } from './components/filtering/filtering.component';
import { StartFilteringComponent } from './components/start-filtering/start-filtering.component';
import { ConfirmStartFilteringComponent } from './components/confirm-start-filtering/confirm-start-filtering.component';
import { FinishFilteringComponent } from './components/finish-filtering/finish-filtering.component';
import { ConfirmFinishFilteringComponent } from './components/confirm-finish-filtering/confirm-finish-filtering.component';
import { CleaningComponent } from './components/cleaning/cleaning.component';
import { RegisterCleaningComponent } from './components/register-cleaning/register-cleaning.component';
import { ConfirmRegisterCleaningComponent } from './components/confirm-register-cleaning/confirm-register-cleaning.component';
import { AuthGuard } from '../login/guards/auth.guard';
import { AdditivesSelectPeriodComponent } from './components/additives-select-period/additives-select-period.component';
import {ReworkSelectMoveComponent} from './components/rework-select-move/rework-select-move.component';
import {ReworkConfirmComponent} from './components/rework-confirm/rework-confirm.component';


const routes: Routes = [
  { path: 'filtro', component: LoginComponent },
  { path: 'filtro', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'procesos/adicion/turno', component: AdditivesSelectPeriodComponent, canActivate: [AuthGuard] },
    { path: 'procesos/adicion/reactor', component: AdditivesComponent, canActivate: [AuthGuard] },
    { path: 'procesos/adicion/confirmar', component: RegisterAdditivesComponent, canActivate: [AuthGuard] },
    { path: 'procesos/retrabajo/reactor', component: ReworkSelectMoveComponent, canActivate: [AuthGuard] },
    { path: 'procesos/retrabajo/confirmar', component: ReworkConfirmComponent, canActivate: [AuthGuard] },
    { path: 'procesos/agitacion', component: ShakingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/agitacion/iniciar', component: StartShakingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/agitacion/iniciar/confirmar', component: ConfirmStartShakingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/agitacion/terminar', component: FinishShakingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/agitacion/terminar/confirmar', component: ConfirmFinishShakingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/filtrado', component: FilteringComponent, canActivate: [AuthGuard] },
    { path: 'procesos/filtrado/iniciar', component: StartFilteringComponent, canActivate: [AuthGuard] },
    { path: 'procesos/filtrado/iniciar/confirmar', component: ConfirmStartFilteringComponent, canActivate: [AuthGuard] },
    { path: 'procesos/filtrado/terminar', component: FinishFilteringComponent, canActivate: [AuthGuard] },
    { path: 'procesos/filtrado/terminar/confirmar', component: ConfirmFinishFilteringComponent, canActivate: [AuthGuard] },
    { path: 'procesos/limpieza', component: CleaningComponent, canActivate: [AuthGuard] },
    { path: 'procesos/limpieza/registrar', component: RegisterCleaningComponent, canActivate: [AuthGuard] },
    { path: 'procesos/limpieza/registrar/confirmar', component: ConfirmRegisterCleaningComponent, canActivate: [AuthGuard] },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FilteringRoutingModule { }
