<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <!-- RESULTADO DE SCANNER -->
            <section class="basic-textarea">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-success text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-success shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-user font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- OPERADOR DETECTADO -->
                                        <p class="card-text">INICIÓ SESIÓN:</p>
                                        <h3 class="mb-2 text-white">{{userData.name}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button (click)="accept()" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-2 waves-effect waves-light">CONFIRMAR</button>
                        <button (click)="back()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-2 waves-effect waves-light">CANCELAR</button>
                    </div>
                </div>
            </section>
            <!-- //RESULTADO DE SCANNER -->
        </div>
    </div>
</div>
