<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR FIN DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <div class="card" style="">
                            <div class="card-content">
                                <div class="card-body">

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            TURNO DEL MOVIMIENTO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" id="" value="{{move.shift.name}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <!-- AGITADOR SELECCIONADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            REACTOR SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" id="" value="{{move.reactor.name}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //AGITADOR SELECCIONADO -->

                                    <!-- TIEMPO REGISTRADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            HORA DE REGISTRO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" id="" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //TIEMPO REGISTRADO -->

                                    <!-- RESIDUOS ADITIVOS -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            RESIDUOS DE ADITIVOS
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [formControl]="additiveResidues" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                            <div class="form-control-position">
                                                <i class="fa fa-tachometer"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //RESIDUOS ADITIVOS -->

                                    <!-- RESIDUOS LÍQUIDOS -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            RESIDUOS LÍQUIDOS
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [formControl]="liquidResidues" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                            <div class="form-control-position">
                                                <i class="fa fa-tachometer"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //RESIDUOS LÍQUIDOS -->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            OBSERVACIONES
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <textarea [formControl]="remarks" type="text" class="form-control" rows="8" id="" placeholder="INGRESA TUS OBSERVACIONES"></textarea>
                                            <div class="form-control-position">
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </fieldset>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <button (click)="submit()" [disabled]="additiveResidues.invalid || liquidResidues.invalid" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>
                        <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
