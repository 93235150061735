import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PrehogorService} from '../../services/prehogor.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-finish',
    templateUrl: './finish.component.html',
    styles: []
})
export class FinishComponent implements OnInit {

    move: any;
    now = new Date();
    remarks = new UntypedFormControl(null);
    loading = false;
    product = '';
    cookers = '';

    constructor(
        private prehogorService: PrehogorService,
        private router: Router
    ) {
        if (!history.state.move) {
            router.navigate(['/prehogor/procesos']);
        }
        setInterval(() => this.now = new Date(), 1000);
    }

    ngOnInit() {
        this.move = history.state.move;
        if (this.move?.multiple) {
            this.product = this.move.product;
            this.cookers = this.move.cookers;
        } else {
            this.product = this.move.period.product.full_name + ' (' + this.move.period.product.short_name + ')';
            this.cookers = this.move.cooker.cooker.cooker_key;
        }
    }

    finish() {
        this.loading = true;
        const body = {
            remarks: this.remarks.value
        };

        if (this.move?.multiple) {
            body['movements'] = this.move.movements;
        }

        const id = this.move?.multiple ? 0 : this.move.movement_id;

        this.prehogorService.finishLoading(id, body).then(resp => {
            this.router.navigate(['/prehogor/procesos']);
        });
    }

    cancel() {
        this.router.navigate(['/prehogor/procesos']);
    }

}
