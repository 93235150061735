import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HoppersService } from '../../../services/hoppers.service';
import { ProductTypesService } from '../../../../production/services/product-types.service';
import { LeftoversService } from '../../../services/leftovers.service';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-leftovers-register',
  templateUrl: './leftovers-register.component.html',
  styles: []
})
export class LeftoversRegisterComponent implements OnInit {

  shift: any;
  now = new Date();
  hoppers = [];
  types = [];
  form: UntypedFormGroup;
  logs: UntypedFormArray;
  loading = false;

  constructor(
    private router: Router,
    private hoppersService: HoppersService,
    private productTypesService: ProductTypesService,
    private leftoversService: LeftoversService,
    private formBuilder: UntypedFormBuilder
  ) {
    if (!history.state.shift) {
      router.navigate(['/molienda/procesos']);
    }
    this.buildForm();
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.loading = true;
    this.shift = history.state.shift;

    const getHoppersProm = this.hoppersService.getAllHoppers();
    const getTypesProm = this.productTypesService.getAll();

    Promise.all([getHoppersProm, getTypesProm]).then(response => {
      this.hoppers = response[0]['data'];
      this.types = response[1] as any;
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      logs: this.formBuilder.array([this.createLog()])
    });

    this.logs = this.form.get('logs') as UntypedFormArray;
  }

  createLog() {
    return this.formBuilder.group({
      hopper: [null, Validators.required],
      paste: [null, Validators.required],
      amount: [null, Validators.required],
    });
  }

  addLog() {
    this.logs = this.form.get('logs') as UntypedFormArray;
    this.logs.push(this.createLog());
  }

  deleteLog(i) {
    this.logs = this.form.get('logs') as UntypedFormArray;
    if (this.logs.length > 1) {
      this.logs.removeAt(i);
    }
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const logs = [];
      for (let i = 0; i < this.logs.length; i++) {
        const l = this.logs.at(i);
        logs.push({
          hopper_id: l.value.hopper,
          product_type_id: l.value.paste,
          amount: l.value.amount,
        });
      }

      const body = {
        shift_id: this.shift.shift_id,
        logs: JSON.stringify(logs)
      };
      this.leftoversService.registerLogs(body).then(resp => {
        this.router.navigate(['/molienda/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

}
