import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnloadService } from '../../services/unload.service';

@Component({
  selector: 'app-unload-detail',
  templateUrl: './unload-detail.component.html',
  styles: []
})
export class UnloadDetailComponent implements OnInit {

  run = {
    run_id: null,
    unload_started_at: null,
  };
  isExternal: boolean;
  loading = false;

  constructor(
    private router: Router,
    private unloadService: UnloadService
  ) {
    if (!history.state.run) {
      router.navigate(['/descarga/inicio']);
    }
  }

  ngOnInit() {
    this.run = history.state.run;
    this.isExternal = history.state.isExternal;
  }

  start() {
    this.loading = true;
    if (this.isExternal) {
      this.unloadService.startExternal(this.run.run_id).then(resp => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      }).catch(err => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      });
    } else {
      this.unloadService.startInternal(this.run.run_id).then(resp => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      }).catch(err => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      });
    }
  }

  finish() {
    this.loading = true;
    if (this.isExternal) {
      this.unloadService.finishExternal(this.run.run_id).then(resp => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      }).catch(err => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      });
    } else {
      this.unloadService.finishInternal(this.run.run_id).then(resp => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      }).catch(err => {
        this.router.navigate(['/descarga/inicio'], {replaceUrl: true});
      });
    }
  }

}
