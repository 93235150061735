import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { AdditivesCheckpointComponent } from './components/additives/additives-checkpoint/additives-checkpoint.component';
import { AdditivesStartSelectShiftComponent } from './components/additives/additives-start-select-shift/additives-start-select-shift.component';
import { AdditivesStartConfirmComponent } from './components/additives/additives-start-confirm/additives-start-confirm.component';
import { AdditivesEndSelectShiftComponent } from './components/additives/additives-end-select-shift/additives-end-select-shift.component';
import { AdditivesEndConfirmComponent } from './components/additives/additives-end-confirm/additives-end-confirm.component';
import { GrindingCheckpointComponent } from './components/grinding/grinding-checkpoint/grinding-checkpoint.component';
import { GrindingSelectShiftComponent } from './components/grinding/grinding-select-shift/grinding-select-shift.component';
import { GrindingConfirmStartComponent } from './components/grinding/grinding-confirm-start/grinding-confirm-start.component';
import { GrindingSelectGrinderFinishComponent } from './components/grinding/grinding-select-grinder-finish/grinding-select-grinder-finish.component';
import { GrindingConfirmFinishComponent } from './components/grinding/grinding-confirm-finish/grinding-confirm-finish.component';
import { LeftoversCheckpointComponent } from './components/leftovers/leftovers-checkpoint/leftovers-checkpoint.component';
import { LeftoversRegisterComponent } from './components/leftovers/leftovers-register/leftovers-register.component';
import { WarehouseCheckpointComponent } from './components/warehouse/warehouse-checkpoint/warehouse-checkpoint.component';
import { WarehouseConfirmCheckComponent } from './components/warehouse/warehouse-confirm-check/warehouse-confirm-check.component';
import { MagnetCheckCheckpointComponent } from './components/magnet-check/magnet-check-checkpoint/magnet-check-checkpoint.component';
import { MagnetCheckSelectShiftComponent } from './components/magnet-check/magnet-check-select-shift/magnet-check-select-shift.component';
import { MagnetCheckCreateCheckComponent } from './components/magnet-check/magnet-check-create-check/magnet-check-create-check.component';
import { AuthGuard } from '../login/guards/auth.guard';


const routes: Routes = [
  { path: 'molienda', component: LoginComponent },
  { path: 'molienda', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'procesos/aditivos', component: AdditivesCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/aditivos/inicio', component: AdditivesStartSelectShiftComponent, canActivate: [AuthGuard] },
    { path: 'procesos/aditivos/inicio/confirmar', component: AdditivesStartConfirmComponent, canActivate: [AuthGuard] },
    { path: 'procesos/aditivos/fin', component: AdditivesEndSelectShiftComponent, canActivate: [AuthGuard] },
    { path: 'procesos/aditivos/fin/confirmar', component: AdditivesEndConfirmComponent, canActivate: [AuthGuard] },
    { path: 'procesos/molienda', component: GrindingCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/molienda/iniciar/periodo', component: GrindingSelectShiftComponent, canActivate: [AuthGuard] },
    { path: 'procesos/molienda/iniciar/confirmar', component: GrindingConfirmStartComponent, canActivate: [AuthGuard] },
    { path: 'procesos/molienda/finalizar/proceso', component: GrindingSelectGrinderFinishComponent, canActivate: [AuthGuard] },
    { path: 'procesos/molienda/finalizar/confirmar', component: GrindingConfirmFinishComponent, canActivate: [AuthGuard] },
    { path: 'procesos/sobrantes', component: LeftoversCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/sobrantes/registrar', component: LeftoversRegisterComponent, canActivate: [AuthGuard] },
    { path: 'procesos/bodegas', component: WarehouseCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/bodegas/registrar', component: WarehouseConfirmCheckComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes', component: MagnetCheckCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/turno', component: MagnetCheckSelectShiftComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/registrar', component: MagnetCheckCreateCheckComponent, canActivate: [AuthGuard] },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrindingRoutingModule { }
