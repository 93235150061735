import { Component, OnInit } from '@angular/core';
import { ExpellersService } from '../../services/expellers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-select-expeller',
  templateUrl: './add-select-expeller.component.html',
  styles: []
})
export class AddSelectExpellerComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private expellersService: ExpellersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.expellersService.getAllActive().then(resp => {
      this.moves = resp['data'] as any;
      this.loading = false;
    });
  }

  select(move: any): void {
    const data = {
      move
    };
    this.router.navigate(['/expellers', 'procesos', 'expeller', 'agregar', 'cocedor'], {state: {data}});
  }

}
