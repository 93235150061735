import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ExpellersService} from '../../services/expellers.service';

@Component({
    selector: 'app-amperage',
    templateUrl: './amperage.component.html',
    styles: []
})
export class AmperageComponent implements OnInit {

    now = new Date();
    expellers = [];
    data: any;
    form: UntypedFormGroup;
    amperagesArr = new UntypedFormArray([]);
    loading = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private expellersService: ExpellersService,
        private router: Router,
    ) {
        if (!history.state.data) {
            router.navigate(['/expellers/procesos']);
        }

        setInterval(() => {
            this.now = new Date();
        }, 1000);

        this._buildForm();
    }

    ngOnInit() {
        this.loading = true;
        this.data = history.state.data;
        this.expellersService.getAll().then(resp => {
            this.expellers = resp as any;
            this._setFormData();
            this.loading = false;
        });
    }

    register() {
        if (this.form.valid) {
            this.loading = true;

            const amperages = [];
            for (let i = 0; i < this.amperagesArr.length; i++) {
                const amp = this.amperagesArr.at(i);
                if (amp.value.amperage !== null) {
                    amperages.push(amp.value);
                }
            }

            const body = {
                expellerMovementId: this.data.move.movement_id,
                cookerMovementId: this.data.cooker.movement_id,
                checks: amperages
            };

            this.expellersService.registerAmperage(body).then(resp => {
                this.router.navigate(['/expellers/procesos']);
            });
        }
    }

    cancel() {
        this.router.navigate(['/expellers/procesos']);
    }

    private _buildForm() {
        this.form = this.formBuilder.group({
            amperages: new UntypedFormArray([])
        });

        this.amperagesArr = this.form.get('amperages') as UntypedFormArray;
    }

    private _setFormData() {
        for (const expeller of this.expellers) {
            this.amperagesArr.push(this.formBuilder.group({
                expellerId: [expeller.expeller_id],
                amperage: [null]
            }));
        }
    }
}
