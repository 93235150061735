import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovementService } from '../../services/movement.service';

@Component({
  selector: 'app-confirm-start-shaking',
  templateUrl: './confirm-start-shaking.component.html',
  styles: []
})
export class ConfirmStartShakingComponent implements OnInit {

  now = new Date();
  move: any;
  loading = false;

  constructor(
    private router: Router,
    private movementService: MovementService
  ) {
    if (!history.state.move) {
      router.navigate(['/filtro/procesos']);
    }
    setInterval(() => this.now = new Date());
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  confirm() {
    this.loading = true;
    this.movementService.startShaking(this.move.movement_id).then(resp => {
      this.router.navigate(['/filtro/procesos']);
    });
  }

  cancel() {
    this.router.navigate(['/filtro/procesos']);
  }

}
