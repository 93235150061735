import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookersService } from '../../services/cookers.service';

@Component({
  selector: 'app-cookers',
  templateUrl: './cookers.component.html',
  styles: []
})
export class CookersComponent implements OnInit {

  moves = [];
  data: any;
  loading = false;

  constructor(
    private cookersService: CookersService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/cocedores', 'procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.cookersService.getWaitingForStartMoves(this.data.period.period_id).then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  selectMove(move) {
    this.data.move = move;
    this.router.navigate(['/cocedores', 'iniciar', 'confirmar'], {state: {data: this.data}});
  }

}
