import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExpellersService } from '../../services/expellers.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-start-process',
  templateUrl: './start-process.component.html',
  styles: []
})
export class StartProcessComponent implements OnInit {

  data: any;
  now = new Date();
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private router: Router,
    private expellersService: ExpellersService
  ) {
    if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  startProcess() {
    this.loading = true;
    const body = {
      period_id: this.data.period.period_id,
      // expeller_id: this.data.expeller.expeller_id,
      remarks: this.remarks.value,
    };

    this.expellersService.startProcess(body).then(resp => {
      this.router.navigate(['/expellers/procesos']);
    });
  }

  cancel() {
    this.router.navigate(['/expellers/procesos']);
  }

}
