import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AuthService} from 'src/app/login/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ShakersService {
    private url = environment.apiUrl;
    private token = '';
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.token = auth.getToken();
        this.headers = new HttpHeaders({Authorization: this.token});
    }

    getAllStandByShakers() {
        const headers = this.headers;
        const url = this.url + `shakers/standby`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    getAllActiveShakers() {
        const headers = this.headers;
        const url = this.url + `shakers/active`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    getWaitingWeighingMoves() {
        const headers = this.headers;
        const url = this.url + `shakers/waiting`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    startProcess(body) {
        const url = this.url + `shakers/start`;
        return this.http.post(url, body, {headers: this.headers})
            .toPromise();
    }

    finishProcess(body) {
        const url = this.url + `shakers/finish`;
        return this.http.put(url, body, {headers: this.headers})
            .toPromise();
    }

    registerWeight(moveID, body) {
        const url = this.url + `shakers/weigh/${moveID}`;
        return this.http.put(url, body, {headers: this.headers})
            .toPromise();
    }

    getValidatedCookerMoves() {
        const url = this.url + `cookers/qa/validated`;
        return this.http.get(url, {headers: this.headers})
            .toPromise();
    }

    assignShakerMove(cookerMoveId, body) {
        const url = this.url + `cookers/shaker/${cookerMoveId}`;
        return this.http.put(url, body, {headers: this.headers})
            .toPromise();
    }
}
