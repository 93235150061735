import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '../layout/main/main.component';
import {LoginComponent} from './components/login/login.component';
import {SmartcardComponent} from './components/smartcard/smartcard.component';
import {CheckpointComponent} from './components/checkpoint/checkpoint.component';
import {SelectShiftStartComponent} from './components/select-shift-start/select-shift-start.component';
import {SelectProductStartComponent} from './components/select-product-start/select-product-start.component';
import {ConfirmStartComponent} from './components/confirm-start/confirm-start.component';
import {SelectPeriodFinishComponent} from './components/select-period-finish/select-period-finish.component';
import {ConfirmFinishComponent} from './components/confirm-finish/confirm-finish.component';
import {AuthGuard} from '../login/guards/auth.guard';
import {EditPeriodSelectPeriodComponent} from './components/edit-period-select-period/edit-period-select-period.component';
import {EditPeriodConfirmComponent} from './components/edit-period-confirm/edit-period-confirm.component';
import {LastClosedPeriodsComponent} from './components/last-closed-periods/last-closed-periods.component';
import {ReopenPeriodComponent} from './components/reopen-period/reopen-period.component';


const routes: Routes = [
    {path: 'periodos', component: LoginComponent},
    {
        path: 'periodos', component: MainComponent, children: [
            {path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard]},
            {path: 'checkpoint', component: CheckpointComponent, canActivate: [AuthGuard]},
            {path: 'iniciar/turno', component: SelectShiftStartComponent, canActivate: [AuthGuard]},
            {path: 'iniciar/producto', component: SelectProductStartComponent, canActivate: [AuthGuard]},
            {path: 'iniciar/confirmar', component: ConfirmStartComponent, canActivate: [AuthGuard]},
            {path: 'finalizar/seleccionar', component: SelectPeriodFinishComponent, canActivate: [AuthGuard]},
            {path: 'finalizar/confirmar', component: ConfirmFinishComponent, canActivate: [AuthGuard]},
            {
                path: 'editar', children: [
                    {path: '', component: EditPeriodSelectPeriodComponent},
                    {path: 'confirmar', component: EditPeriodConfirmComponent},
                ]
            },
            {
                path: 'reabrir', children: [
                    {path: '', component: LastClosedPeriodsComponent},
                    {path: 'confirmar', component: ReopenPeriodComponent},
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PeriodRoutingModule {
}
