<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> INGRESAR TEMPERATURA REGISTRADA
            </div>
            <form [formGroup]="form" (ngSubmit)="startProcess()">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    TURNO
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="text" class="form-control" value="{{data.period.shift.name}}"
                                           disabled="">
                                    <div class="form-control-position">
                                        <i class="fa fa-user"></i>
                                    </div>
                                </fieldset>
                            </div>

                            <!-- MATERIA PRIMA -->
                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    PRODUCTO ASIGNADO
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="text" class="form-control"
                                           value="{{data.period.product.full_name}} ({{data.period.product.short_name}})"
                                           disabled="">
                                    <div class="form-control-position">
                                        <i class="fa fa-pie-chart"></i>
                                    </div>
                                </fieldset>
                            </div>

                            <!-- MATERIA PRIMA -->
                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    INICIO PERIODO
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="text" class="form-control"
                                           value="{{data.period.started_at|date:'dd/MM/y hh:mm:ss a'}}"
                                           disabled="">
                                    <div class="form-control-position">
                                        <i class="fa fa-pie-chart"></i>
                                    </div>
                                </fieldset>
                            </div>
                            <!-- //MATERIA PRIMA -->

                            <!-- COCEDOR SELECCIONADO -->
                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    COCEDOR SELECCIONADO
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="text" class="form-control" value="{{data.move.cooker.cooker_key}}"
                                           disabled="">
                                    <div class="form-control-position">
                                        <i class="fa fa-industry"></i>
                                    </div>
                                </fieldset>
                            </div>
                            <!-- //COCEDOR SELECCIONADO -->

                            <!-- COCEDOR SELECCIONADO -->
                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    HORA DE REVISIÓN
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="text" class="form-control" value="{{now|date:'hh:mm:ss a'}}"
                                           disabled="">
                                    <div class="form-control-position">
                                        <i class="fa fa-clock-o"></i>
                                    </div>
                                </fieldset>
                            </div>
                            <!-- //COCEDOR SELECCIONADO -->
                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    HARINA DE DECANTE
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                    <input type="number" formControlName="added_decanter_flour" class="form-control"
                                           placeholder="INGRESA LA CANTIDAD DE HARINA AGREGADA (KG)">
                                    <div class="form-control-position">
                                        <i class="fa fa-balance-scale"></i>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <div class="text-bold-600 font-medium-2 mb-1">
                                    OBSERVACIONES
                                </div>
                                <fieldset class="form-group position-relative has-icon-left">
                                <textarea formControlName="remarks" type="text" class="form-control" rows="8" id=""
                                          placeholder="INGRESA TUS OBSERVACIONES"></textarea>
                                    <div class="form-control-position">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="text-bold-600 font-medium-2 mb-1">
                            TEMPERATURA REGISTRADA
                        </div>
                        <fieldset class="form-group position-relative has-icon-left">
                            <input formControlName="temperature" type="number" class="form-control"
                                   placeholder="INGRESAR TEMPERATURA EN °C">
                            <div class="form-control-position">
                                <i class="fa fa-thermometer-half"></i>
                            </div>
                        </fieldset>

                        <div class="text-bold-600 font-medium-2 mb-1">
                            PRESIÓN REGISTRADA
                        </div>
                        <fieldset class="form-group position-relative has-icon-left">
                            <input formControlName="pressure" type="number" class="form-control" placeholder="INGRESAR PRESIÓN">
                            <div class="form-control-position">
                                <i class="fa fa-tachometer"></i>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <button [disabled]="form.invalid" type="submit"
                        class="btn btn-lg btn-block btn-relief-success mb-3 waves-effect waves-light">CONFIRMAR
                </button>

                <button [routerLink]="['/cocedores']" type="button"
                        class="btn btn-lg btn-block btn-relief-danger mb-3 waves-effect waves-light">CANCELAR
                </button>
            </form>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
