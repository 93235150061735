<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR FIN DE PERÍODO
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <div class="card" style="">
                            <div class="card-content">
                                <div class="card-body">

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            TURNO SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{period.shift.name}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- MOLINO SELECCIONADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            PRODUCTO SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{period.product.full_name}} ({{period.product.short_name}})" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //MOLINO SELECCIONADO -->

                                    <!-- TIEMPO REGISTRADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            FIN DE OPERACIÓN
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //TIEMPO REGISTRADO -->
                                </div>
                            </div>
                        </div>
                        <button (click)="submit()" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                        <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
