import { Component, OnInit } from '@angular/core';
import { ExpellersService } from '../../services/expellers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finish-process-select-expeller',
  templateUrl: './finish-process-select-expeller.component.html',
  styles: []
})
export class FinishProcessSelectExpellerComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private expellersService: ExpellersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.expellersService.getAllActive().then(resp => {
      this.moves = resp['data'] as any;
      this.loading = false;
    });
  }

  select(move: any) {
    this.router.navigate(['/expellers', 'procesos', 'expeller', 'finalizar', 'confirmar'], {state: {move}});
  }

}
