import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookersRoutingModule } from './cookers-routing.module';
import { CookersComponent } from './components/cookers/cookers.component';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { RegisterTemperatureComponent } from './components/register-temperature/register-temperature.component';
import { ConfirmStartComponent } from './components/confirm-start/confirm-start.component';
import { TempSelectCookerComponent } from './components/temp-select-cooker/temp-select-cooker.component';
import { FinishSelectCookerComponent } from './components/finish-select-cooker/finish-select-cooker.component';
import { ConfirmFinishComponent } from './components/confirm-finish/confirm-finish.component';
import { CookersSelectOriginComponent } from './components/cookers-select-origin/cookers-select-origin.component';
import { CookerPressureSelectActiveComponent } from './components/cooker-pressure-select-active/cooker-pressure-select-active.component';
import { CookerPressureRegisterComponent } from './components/cooker-pressure-register/cooker-pressure-register.component';
import { StartSelectPeriodComponent } from './components/start-select-period/start-select-period.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';


@NgModule({
  declarations: [CookersComponent, LoginComponent, SmartcardComponent, ProcessesComponent, CheckpointComponent, SelectProductComponent, RegisterTemperatureComponent, ConfirmStartComponent, TempSelectCookerComponent, FinishSelectCookerComponent, ConfirmFinishComponent, CookersSelectOriginComponent, CookerPressureSelectActiveComponent, CookerPressureRegisterComponent, StartSelectPeriodComponent],
  imports: [
    CommonModule,
    CookersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class CookersModule { }
