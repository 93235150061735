import { Component, OnInit } from '@angular/core';
import { GrindingService } from '../../../services/grinding.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grinding-select-grinder-finish',
  templateUrl: './grinding-select-grinder-finish.component.html',
  styles: []
})
export class GrindingSelectGrinderFinishComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private grindingService: GrindingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.grindingService.getActiveMovements().then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/molienda/procesos/molienda/finalizar/confirmar'], {state: {move}});
  }

}
