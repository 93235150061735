import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PeriodService} from '../../services/period.service';

@Component({
  selector: 'app-confirm-finish',
  templateUrl: './confirm-finish.component.html',
  styles: []
})
export class ConfirmFinishComponent implements OnInit {

  period: any;
  now = new Date();
  loading = false;

  constructor(
    private router: Router,
    private periodService: PeriodService
  ) {
    if (!history.state.period) {
      router.navigate(['/periodos/checkpoint']);
    }

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.period = history.state.period;
  }

  submit() {
    this.loading = true;
    this.periodService.finishPeriod(this.period.period_id).then(resp => {
      this.router.navigate(['/periodos']);
    });
  }

  cancel() {
    this.router.navigate(['/periodos']);
  }
}
