import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { RegisterTemperatureComponent } from './components/register-temperature/register-temperature.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { CookersComponent } from './components/cookers/cookers.component';
import { ConfirmStartComponent } from './components/confirm-start/confirm-start.component';
import { TempSelectCookerComponent } from './components/temp-select-cooker/temp-select-cooker.component';
import { FinishSelectCookerComponent } from './components/finish-select-cooker/finish-select-cooker.component';
import { ConfirmFinishComponent } from './components/confirm-finish/confirm-finish.component';
import { CookerPressureSelectActiveComponent } from './components/cooker-pressure-select-active/cooker-pressure-select-active.component';
import { CookerPressureRegisterComponent } from './components/cooker-pressure-register/cooker-pressure-register.component';
import { StartSelectPeriodComponent } from './components/start-select-period/start-select-period.component';
import { AuthGuard } from '../login/guards/auth.guard';


const routes: Routes = [
  { path: 'cocedores', component: LoginComponent },
  { path: 'cocedores', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'checkpoint', component: CheckpointComponent, canActivate: [AuthGuard] },
    { path: 'iniciar/periodo', component: StartSelectPeriodComponent, canActivate: [AuthGuard] },
    { path: 'iniciar/cocedor', component: CookersComponent, canActivate: [AuthGuard] },
    { path: 'iniciar/confirmar', component: ConfirmStartComponent, canActivate: [AuthGuard] },
    { path: 'temperatura/seleccionar', component: TempSelectCookerComponent, canActivate: [AuthGuard] },
    { path: 'temperatura/confirmar', component: RegisterTemperatureComponent, canActivate: [AuthGuard] },
    { path: 'presion/seleccionar', component: CookerPressureSelectActiveComponent, canActivate: [AuthGuard] },
    { path: 'presion/confirmar', component: CookerPressureRegisterComponent, canActivate: [AuthGuard] },
    { path: 'terminar/seleccionar', component: FinishSelectCookerComponent, canActivate: [AuthGuard] },
    { path: 'terminar/confirmar', component: ConfirmFinishComponent, canActivate: [AuthGuard] },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CookersRoutingModule { }
