<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR INICIO DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <!-- DATOS REGISTRADOS -->
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <!-- AGITADOR SELECCIONADO -->

                            <div class="text-bold-600 font-medium-2 mb-1">
                                TURNO SELECCIONADO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control" value="{{data.shift.name}}"
                                       disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-industry"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                AGITADOR SELECCIONADO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{data.shaker.shaker_key}}" disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-industry"></i>
                                </div>
                            </fieldset>
                            <!-- //AGITADOR SELECCIONADO -->

                            <!-- TIEMPO REGISTRADO -->
                            <div class="text-bold-600 font-medium-2 mb-1">
                                INICIO DE OPERACIÓN
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                            </fieldset>
                            <!-- //TIEMPO REGISTRADO -->

                            <!-- TEMPERATURA REGISTRADA -->
                            <div class="text-bold-600 font-medium-2 mb-1">
                                TEMPERATURA REGISTRADA
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input formControlName="temperature" type="number" class="form-control"
                                       placeholder="INGRESAR TEMPERATURA EN °C">
                                <div class="form-control-position">
                                    <i class="fa fa-thermometer-half"></i>
                                </div>
                            </fieldset>
                            <!-- //TEMPERATURA REGISTRADA -->

                            <!-- VÁLVULA ALIMENTACIÓN -->
                            <div class="text-bold-600 font-medium-2 mb-1">
                                VALVULA DE ALIMENTACIÓN
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input formControlName="supplyValve" type="number" class="form-control"
                                       placeholder="INGRESAR NO. DE VÁLVULA">
                                <div class="form-control-position">
                                    <i class="fa fa-cogs"></i>
                                </div>
                            </fieldset>
                            <!-- //VÁLVULA ALIMENTACIÓN -->

                            <!-- AMPERAJE ARRANQUE -->
                            <div class="text-bold-600 font-medium-2 mb-1">
                                AMPERES REGISTRADOS
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input formControlName="amperage" type="number" class="form-control"
                                       placeholder="INGRESAR AMPERES REGISTRADOS">
                                <div class="form-control-position">
                                    <i class="fa fa-bolt"></i>
                                </div>
                            </fieldset>
                            <!-- //AMPERAJE ARRANQUE -->

                            <div class="text-bold-600 font-medium-2 mb-1">
                                TIEMPO DE ENCENDIDO DE AGITADOR
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input formControlName="ignitionTime" type="text" class="form-control"
                                       placeholder="INGRESAR TIEMPO DE ENCENDIDO">
                                <div class="form-control-position">
                                    <i class="fa fa-tachometer"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                OBSERVACIONES
                            </div>
                            <fieldset class="position-relative has-icon-left">
                                <textarea formControlName="remarks" rows="5" class="form-control"
                                          placeholder="INGRESAR OBSERVACIONES" style="resize: none"></textarea>
                                <div class="form-control-position">
                                    <i class="fa fa-sticky-note"></i>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <button [disabled]="form.invalid" type="submit"
                        class="btn btn-lg btn-block btn-relief-success mb-2 waves-effect waves-light">CONFIRMAR
                </button>

                <button (click)="cancel()" type="button"
                        class="btn btn-lg btn-block btn-relief-danger mb-1 waves-effect waves-light">CANCELAR
                </button>
            </form>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
