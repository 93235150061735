import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { EntryComponent } from './components/entry/entry.component';
import { EntryVehicleDetailComponent } from './components/entry/entry-vehicle-detail/entry-vehicle-detail.component';
import { EntryWeighingComponent } from './components/entry/entry-weighing/entry-weighing.component';
import { DepartureComponent } from './components/departure/departure.component';
import { DepartingVehicleDetailComponent } from './components/departure/departing-vehicle-detail/departing-vehicle-detail.component';
import { DepartureWeighingComponent } from './components/departure/departure-weighing/departure-weighing.component';
import { AuthGuard } from './guards/auth.guard';
import { WeighingLoginComponent } from './components/weighing-login/weighing-login.component';
import { WeighingDetectedUserComponent } from './components/weighing-detected-user/weighing-detected-user.component';


const routes: Routes = [
  { path: 'bascula', component: WeighingLoginComponent },
  { path: 'bascula', component: MainComponent, children: [
    { path: 'smartcard', component: WeighingDetectedUserComponent, canActivate: [AuthGuard]},
    { path: 'inicio', component: MainMenuComponent, canActivate: [AuthGuard]},
    { path: 'ingreso', component: EntryComponent, canActivate: [AuthGuard] },
    { path: 'ingreso/:id', component: EntryVehicleDetailComponent, canActivate: [AuthGuard] },
    { path: 'ingreso/:id/pesar', component: EntryWeighingComponent, canActivate: [AuthGuard] },
    { path: 'salida', component: DepartureComponent, canActivate: [AuthGuard] },
    { path: 'salida/:id', component: DepartingVehicleDetailComponent, canActivate: [AuthGuard] },
    { path: 'salida/:id/pesar', component: DepartureWeighingComponent, canActivate: [AuthGuard] },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WeighingRoutingModule { }
