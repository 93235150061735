import { Component, OnInit } from '@angular/core';
import { UnloadService } from '../../services/unload.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unload-main-menu',
  templateUrl: './unload-main-menu.component.html',
  styles: []
})
export class UnloadMainMenuComponent implements OnInit {

  runs = {
    internal: [],
    external: []
  };
  loading = false;

  constructor(
    private unloadService: UnloadService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.unloadService.getVehicles().then(resp => {
      this.runs = resp as any;
      this.loading = false;
    });
  }

  detail(run, isExternal = false) {
    this.router.navigate(['/descarga/detalle'], {state: {run, isExternal}});
  }

}
