<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">

            <!-- DATOS DE RUTAS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- UNIDAD DISPONIBLE -->
                    <div *ngIf="run.unload_started_at === null" class="col-sm-12">
                        <div class="card text-white bg-gradient-success text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-success shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-clock-o font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="mb-2 text-white">INICIAR DESCARGA</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="start()" type="button" class="btn btn-lg btn-relief-success mr-1 mb-2 waves-effect waves-light">REGISTRAR INICIO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //UNIDAD DISPONIBLE -->

                    <!-- AL REGISTRAR INICIO DE DESCARGA SE ELIMINA EL CONTENEDOR VERDE Y SE PONE SOLO EL ROJO -->

                    <!-- UNIDAD TERMINADA -->
                    <div *ngIf="run.unload_started_at !== null" class="col-sm-12">
                        <div class="card text-white bg-gradient-danger text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-danger shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-clock-o font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="mb-2 text-white">TERMINAR DESCARGA</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="finish()" type="button" class="btn btn-lg btn-relief-danger mr-1 mb-2 waves-effect waves-light">REGISTRAR FIN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //RUTA TERMINADA -->

                </div>
            </section>
            <!-- //DATOS DE RUTA DISPONIBLE -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
