import { Component, OnInit } from '@angular/core';
import { AntioxidantService } from '../../../services/antioxidant.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-antioxidant-end-select-shift',
  templateUrl: './antioxidant-end-select-shift.component.html',
  styles: []
})
export class AntioxidantEndSelectShiftComponent implements OnInit {

  checks = [];
  loading = false;

  constructor(
    private antioxidantService: AntioxidantService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.antioxidantService.getActiveChecks().then(resp => {
      this.checks = resp['data'];
      this.loading = false;
    });
  }

  select(check) {
    this.router.navigate(['/tanques/procesos/antioxidante/fin/confirmar'], {state: {check}});
  }

}
