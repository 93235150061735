import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkShiftsService } from '../../../production/services/work-shifts.service';

@Component({
  selector: 'app-additives-select-period',
  templateUrl: './additives-select-period.component.html',
  styles: []
})
export class AdditivesSelectPeriodComponent implements OnInit {

  shifts = [];
  loading = false;

  constructor(
    private workShiftsService: WorkShiftsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.workShiftsService.getAll().then(resp => {
      this.shifts = resp['data'];
      this.loading = false;
    });
  }

  select(shift) {
    const data = {
      shift
    };
    this.router.navigate(['/filtro/procesos/adicion/reactor'], {state: {data}});
  }

}
