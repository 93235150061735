import { Component, OnInit } from '@angular/core';
import { ShakersService } from '../../../services/shakers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styles: []
})
export class StartComponent implements OnInit {

  shakers = [];
  data: any;
  loading = false;

  constructor(
    private shakersService: ShakersService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/tanques/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.shakersService.getAllStandByShakers().then(resp => {
      this.shakers = resp as any;
      this.loading = false;
    });
  }

  select(shaker) {
    this.data.shaker = shaker;
    this.router.navigate(['/tanques/procesos/centrifuga/iniciar/confirmar'], {state: {data: this.data}});
  }

}
