<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR SELECCIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <!-- AGITADOR SELECCIONADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            AGITADOR SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control"
                                                   value="{{data.move.shaker.shaker_key}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //AGITADOR SELECCIONADO -->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            COCEDOR SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control"
                                                   value="{{data.cooker.cooker.cooker_key}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-database"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            PROCEDENCIA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control"
                                                   value="{{data.cooker.origins}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-map-marker"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <!-- TIEMPO REGISTRADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            FIN DE CARGA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control"
                                                   value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button (click)="submit()" type="button"
                                class="btn btn-lg btn-block btn-relief-success mb-2 waves-effect waves-light">CONFIRMAR
                        </button>

                        <button (click)="cancel()" type="button"
                                class="btn btn-lg btn-block btn-relief-danger mb-1 waves-effect waves-light">CANCELAR
                        </button>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
