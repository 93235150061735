import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {PeriodService} from '../../services/period.service';
import {ProductTypesService} from '../../../production/services/product-types.service';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-period-confirm',
  templateUrl: './edit-period-confirm.component.html',
  styles: [
  ]
})
export class EditPeriodConfirmComponent implements OnInit {

    loading = false;

    period: any;
    products = [];

    productControl = new UntypedFormControl(null, Validators.required);

    constructor(
        private router: Router,
        private periodService: PeriodService,
        private productTypesService: ProductTypesService
    ) {
        if (!history.state.period) {
            router.navigate(['/periodos/checkpoint']);
        }
    }

    ngOnInit() {
        this.loading = true;
        this.period = history.state.period;

        this.productTypesService.getAll().then(response => {
            this.products = response as any;

            this.productControl.setValue(this.period.product.type_id);
            this.loading = false;
        });
    }

    submit() {
        this.loading = true;

        const body = {
            typeId: this.productControl.value
        };

        this.periodService.updatePeriod(this.period.period_id, body).then(() => {
            this.router.navigate(['/periodos']);
        });
    }

    cancel() {
        this.router.navigate(['/periodos']);
    }

}
