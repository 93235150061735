import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExpellersService } from '../../../services/expellers.service';

@Component({
  selector: 'app-magnets-expeller',
  templateUrl: './magnets-expeller.component.html',
  styles: []
})
export class MagnetsExpellerComponent implements OnInit {

  period: any;
  expellers = [];
  loading = false;

  constructor(
    private router: Router,
    private expellersService: ExpellersService
  ) {
    if (!history.state.period) {
      router.navigate(['/expellers/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.period = history.state.period;
    this.expellersService.getAll().then(resp => {
      this.expellers = resp as any;
      this.loading = false;
    });
  }

  select(expeller) {
    const data = {
      period: this.period,
      expeller
    };
    this.router.navigate(['/expellers', 'procesos', 'imanes', 'seleccionar'], {state: {data}});
  }

}
