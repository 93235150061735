import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExpellersService } from '../../services/expellers.service';

@Component({
  selector: 'app-add-cooker-confirm',
  templateUrl: './add-cooker-confirm.component.html',
  styles: []
})
export class AddCookerConfirmComponent implements OnInit {

  now = new Date();
  data: any;
  loading = false;

  constructor(
    private router: Router,
    private expellersService: ExpellersService
  ) {
    if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  register() {
    this.loading = true;
    const body = {
      cookerMoveID: this.data.cooker.movement_id,
    };
    this.expellersService.addCookerToExpeller(body, this.data.move.movement_id).then(resp => {
      this.router.navigate(['/expellers/procesos']);
    });
  }

  cancel() {
    this.router.navigate(['/expellers/procesos']);
  }

}
