import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MovementService} from '../../services/movement.service';

@Component({
    selector: 'app-rework-confirm',
    templateUrl: './rework-confirm.component.html',
    styles: []
})
export class ReworkConfirmComponent implements OnInit {

    loading = false;
    move: any;
    form: UntypedFormGroup;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private movementService: MovementService
    ) {
        if (!history.state.move) {
            this.router.navigate(['/filtro/procesos']);
        }

        this._buildForm();
    }

    ngOnInit(): void {
        this.move = history.state.move;
        this._setFormData();
    }

    cancel() {
        this.router.navigate(['/filtro/procesos']);
    }

    submit() {
        this.loading = true;
        this.movementService.updateAdditives(this.move.movement_id, this.form.value).then(
            () => {
                this.router.navigate(['/filtro/procesos']);
            }
        );
    }

    private _buildForm() {
        this.form = this.formBuilder.group({
            tonsil: [null, Validators.required],
            carbon: [null, Validators.required],
        });
    }

    private _setFormData() {
        this.form.setValue({
            tonsil: this.move.added_tonsil,
            carbon: this.move.added_activated_carbon,
        });
    }
}
