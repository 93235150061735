import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AdditivesService } from '../../../services/additives.service';

@Component({
  selector: 'app-additives-start-confirm',
  templateUrl: './additives-start-confirm.component.html',
  styles: []
})
export class AdditivesStartConfirmComponent implements OnInit {

  shift: any;
  now = new Date();
  antioxidant = new UntypedFormControl(null, Validators.required);
  salmonellicide = new UntypedFormControl(null, Validators.required);
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private router: Router,
    private additivesService: AdditivesService
  ) {
    if (!history.state.shift) {
      router.navigate(['/molienda/procesos']);
    }

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.shift = history.state.shift;
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

  submit() {
    if (this.antioxidant.valid && this.salmonellicide.valid) {
      this.loading = true;
      const body = {
        shift_id: this.shift.shift_id,
        antioxidant: this.antioxidant.value,
        salmonellicide: this.salmonellicide.value,
        remarks: this.remarks.value
      };
      this.additivesService.registerStartCheck(body).then(resp => {
        this.router.navigate(['/molienda/procesos']);
      });
    }
  }

}
