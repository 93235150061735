import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrehogorService } from '../../services/prehogor.service';

@Component({
  selector: 'app-finish-cooker',
  templateUrl: './finish-cooker.component.html',
  styles: []
})
export class FinishCookerComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private prehogorService: PrehogorService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.prehogorService.getActiveMoves().then(resp => {
      this.moves = resp as Array<any>;
      this.loading = false;
    });
  }

  finish(move) {
      this.router.navigate(['/prehogor', 'finalizar', 'confirmar'], {state: {move}});
  }

}
