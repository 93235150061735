import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { SelectCookerComponent } from './components/select-cooker/select-cooker.component';
import { SelectTypeComponent } from './components/select-type/select-type.component';
import { StartComponent } from './components/start/start.component';
import { FinishCookerComponent } from './components/finish-cooker/finish-cooker.component';
import { FinishComponent } from './components/finish/finish.component';
import { LoginComponent } from './components/login/login.component';
import { SelectOriginComponent } from './components/select-origin/select-origin.component';
import { AuthGuard } from '../login/guards/auth.guard';
import { AntioxidantComponent } from './components/antioxidant/antioxidant.component';
import { StartSelectShiftComponent } from './components/antioxidant/start-select-shift/start-select-shift.component';
import { StartConfirmComponent } from './components/antioxidant/start-confirm/start-confirm.component';
import { FinishSelectShiftComponent } from './components/antioxidant/finish-select-shift/finish-select-shift.component';
import { FinishConfirmComponent } from './components/antioxidant/finish-confirm/finish-confirm.component';

const routes: Routes = [
  { path: 'prehogor', component: LoginComponent },
  { path: 'prehogor', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'checkpoint', component: CheckpointComponent, canActivate: [AuthGuard] },
    { path: 'iniciar/procedencia', component: SelectOriginComponent, canActivate: [AuthGuard] },
    { path: 'iniciar/periodo', component: SelectTypeComponent, canActivate: [AuthGuard]},
    { path: 'iniciar/cocedor', component: SelectCookerComponent, canActivate: [AuthGuard]},
    { path: 'iniciar/confirmar', component: StartComponent, canActivate: [AuthGuard]},
    { path: 'finalizar/cocedor', component: FinishCookerComponent, canActivate: [AuthGuard]},
    { path: 'finalizar/confirmar', component: FinishComponent, canActivate: [AuthGuard]},
    { path: 'antioxidante', component: AntioxidantComponent, canActivate: [AuthGuard]},
    { path: 'antioxidante/inicio', component: StartSelectShiftComponent, canActivate: [AuthGuard]},
    { path: 'antioxidante/inicio/confirmar', component: StartConfirmComponent, canActivate: [AuthGuard]},
    { path: 'antioxidante/fin', component: FinishSelectShiftComponent, canActivate: [AuthGuard]},
    { path: 'antioxidante/fin/confirmar', component: FinishConfirmComponent, canActivate: [AuthGuard]},
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrehogorRoutingModule { }
