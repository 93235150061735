<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR COCEDOR DISPONIBLE
            </div>
            <!-- DATOS DE COCEDORESS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- COCEDOR DISPONIBLE -->
                    <div *ngFor="let w of waiting" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="text-white">{{w.cooker.cooker_key}}</h1>
                                        <h3 class="text-white">CON MATERIA PRIMA DE:</h3>
                                        <span class="text-white">{{w.origins}}</span>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="selectMove(w)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let c of standBy" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="text-white">{{c.cooker_key}}</h1>
                                        <h3 class="text-white">VACÍO</h3>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="selectCooker(c)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //COCEDOR DISPONIBLE -->
                </div>
            </section>
            <!-- //DATOS DE COCEDORES DISPONIBLES -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
