<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> INGRESAR AMPERES DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="register()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                PRODUCTO ASIGNADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                       value="{{data.move.period.product.short_name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                COCIMIENTO SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                       value="{{data.cooker.cooker.cooker_key}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-fire"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HORA DE REVISIÓN
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                       value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <ng-container formArrayName="amperages">
                                            <div *ngFor="let _ of amperagesArr.controls; index as i"
                                                 class="col-sm-12 col-md-4">
                                                <ng-container [formGroupName]="i">
                                                    <div class="form-group">
                                                        <label class="control-label col-form-label">
                                                            {{expellers[i].expeller_key}}
                                                        </label>
                                                        <fieldset class="form-group position-relative has-icon-left">
                                                            <input formControlName="amperage"
                                                                   placeholder="INGRESA EL AMPERAJE"
                                                                   type="number" step="0.1"
                                                                   class="form-control">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-industry"></i>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <button type="submit"
                                    class="btn btn-lg btn-block btn-relief-success mb-3 waves-effect waves-light">
                                CONFIRMAR
                            </button>

                            <button (click)="cancel()" type="button"
                                    class="btn btn-lg btn-block btn-relief-danger mb-3 waves-effect waves-light">
                                CANCELAR
                            </button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
