<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR FIN DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                        <!-- AGITADOR SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                AGITADOR SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" id="" value="{{move.shaker.shaker_key}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //AGITADOR SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                FIN DE CARGA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" id="" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <!-- <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HARINA DE DECANTE
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="decanterFlour" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-bar-chart"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                VOLUMEN DE SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="tallowVolume" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-bar-chart"></i>
                                                </div>
                                            </fieldset>
                                        </div> -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                OBSERVACIONES
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <textarea formControlName="remarks" rows="5" class="form-control" placeholder="INGRESAR OBSERVACIONES"></textarea>
                                                <div class="form-control-position">
                                                    <i class="fa fa-sticky-note"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <button [disabled]="form.invalid" type="submit" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                            <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
