<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR INICIO DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- AGITADOR SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                AGITADOR SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.reactor.name}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //AGITADOR SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                INICIO DE OPERACIÓN
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <!-- BOMBA UTILIZADA -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                BOMBA DE SUCCIÓN
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <select formControlName="pump" class="select2 form-control">
                                                    <option [ngValue]="null" selected disabled>Selecciona una opción...
                                                    </option>
                                                    <option *ngFor="let p of pumps" [ngValue]="p.pump_id">{{p.name}}
                                                    </option>
                                                </select>
                                                <div class="form-control-position">
                                                    <i class="fa fa-code-fork" aria-hidden="true"></i>
                                                </div>

                                            </fieldset>
                                        </div>
                                        <!-- //BOMBA UTILIZADA -->

                                        <!-- PRESIÓN DE BOMBA -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                PRESIÓN DE BOMBA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="number" formControlName="pumpPressure" class="form-control"
                                                    placeholder="INGRESAR PRESIÓN DE BOMBA">
                                                <div class="form-control-position">
                                                    <i class="fa fa-tachometer"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //PRESIÓN DE BOMBA -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                VOLUMEN DE SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.tallow_volume|number}} KG."
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                DESTINO DEL SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <select formControlName="destination" class="select2 form-control">
                                                    <option [ngValue]="null" selected disabled>Selecciona una opción...
                                                    </option>
                                                    <option *ngFor="let d of destinations" [ngValue]="d.destination_id">{{d.name}}
                                                    </option>
                                                </select>
                                                <div class="form-control-position">
                                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                </div>

                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                % DE A.G.L.
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.agl|number}} %"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                AMARILLOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.yellow|number}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                ROJOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.red|number}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <!-- PRESIÓN DE BOMBA -->

                                        <!-- //PRESIÓN DE BOMBA -->

                                    </div>
                                </div>
                            </div>
                             <button type="submit" [disabled]="form.invalid"
                                class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>
                            <button (click)="cancel()" type="button"
                                class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
