import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { SmartcardMainComponent } from './components/smartcard-main/smartcard-main.component';


@NgModule({
  declarations: [LoginComponent, SmartcardMainComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule
  ],
  exports: [
    LoginComponent,
    SmartcardMainComponent
  ]
})
export class LoginModule { }
