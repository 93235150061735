import { Component, OnInit } from '@angular/core';
import { CookersService } from 'src/app/cookers/services/cookers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-cooker',
  templateUrl: './select-cooker.component.html',
  styles: []
})
export class SelectCookerComponent implements OnInit {

  standBy = [];
  waiting = [];
  data: any;
  loading = false;

  constructor(
    private cookersService: CookersService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/prehogor/procesos'], {replaceUrl: true});
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    const getStandbyCookersProm = this.cookersService.getAllStandBy();
    const getWaitingCookersthisProm = this.cookersService.getWaitingForStartMoves(this.data.period.period_id);

    Promise.all([getStandbyCookersProm, getWaitingCookersthisProm]).then(resp => {
      this.standBy = resp[0] as Array<any>;
      this.waiting = resp[1]['data'];
      this.loading = false;
    });
  }

  selectCooker(cooker) {
    this.data.cooker = cooker;
    this.data.isCooker = true;
    this.confirm();
  }

  selectMove(move) {
    this.data.cooker = move;
    this.data.isCooker = false;
    this.confirm();
  }

  confirm() {
    this.router.navigate(['/prehogor', 'iniciar', 'confirmar'], {state: {data: this.data}});
  }

}
