<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR INICIO DE CARGA
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <!-- PRODUCTO ASIGNADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            RUTA DE PROCEDENCIA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.run.origin|uppercase}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-pie-chart"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            UNIDAD
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.run.vehicle|uppercase}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-pie-chart"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            PRODUCTO ASIGNADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.period.product.short_name}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-pie-chart"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            INICIO PERIODO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.period.started_at|date:'dd/MM/y hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            KILOGRAMOS DISPONIBLES
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.run.available|number:'1.2-2'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-pie-chart"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //PRODUCTO ASIGNADO -->

                                    <!-- COCEDOR SELECCIONADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            COCEDOR SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input *ngIf="data.isCooker === true" type="text" class="form-control" value="{{data.cooker.cooker_key}}" disabled="">
                                            <input *ngIf="data.isCooker === false" type="text" class="form-control" value="{{data.cooker.cooker.cooker_key}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //COCEDOR SELECCIONADO -->

                                    <!-- TIEMPO REGISTRADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            INICIO DE CARGA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            CANTIDAD DE MATERIA PRIMA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [formControl]="amount" type="number" class="form-control" placeholder="INGRESA LA CANTIDAD DE MATERIA PRIMA (KG)">
                                            <div class="form-control-position">
                                                <i class="fa fa-balance-scale"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            OBSERVACIONES
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <textarea [formControl]="remarks" type="text" class="form-control" rows="8" placeholder="INGRESA TUS OBSERVACIONES"></textarea>
                                            <div class="form-control-position">
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //TIEMPO REGISTRADO -->

                                </div>
                            </div>
                        </div>
                        <button [disabled]="amount.invalid" (click)="start()" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                        <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
