import { Component, OnInit } from '@angular/core';
import { MovementService } from '../../services/movement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-cleaning',
  templateUrl: './register-cleaning.component.html',
  styles: []
})
export class RegisterCleaningComponent implements OnInit {

  moves: any;
  loading = false;

  constructor(
    private movementService: MovementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.movementService.getWaitingCleaningMovements().then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  select(move: any) {
    this.router.navigate(['/filtro/procesos/limpieza/registrar/confirmar'], {state: {move}});
  }

}
