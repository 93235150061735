import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { ShakersService } from '../../../services/shakers.service';

@Component({
  selector: 'app-confirm-start',
  templateUrl: './confirm-start.component.html',
  styles: []
})
export class ConfirmStartComponent implements OnInit {

  data: any;
  now = new Date();
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private shakersService: ShakersService,
  ) {
    if (!history.state.data) {
      this.router.navigate(['/tanques/procesos']);
    }
    this.buildForm();
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      temperature: [null, Validators.required],
      supplyValve: [null, Validators.required],
      amperage: [null, Validators.required],
      ignitionTime: [null, Validators.required],
      remarks: [null],
    });

  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        shift_id: this.data.shift.shift_id,
        shaker_id: this.data.shaker.shaker_id,
        temperature: this.form.value.temperature,
        supply_valve: this.form.value.supplyValve,
        amperage: this.form.value.amperage,
        ignition_time: this.form.value.ignitionTime,
        remarks: this.form.value.remarks,
      };

      this.shakersService.startProcess(body).then(resp => {
        this.router.navigate(['/tanques/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/tanques/procesos'], {replaceUrl: true});
  }

}
