import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AuthService} from 'src/app/login/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ExpellersService {
    private expellersUrl = environment.apiUrl + 'expellers';
    private amperageUrl = environment.apiUrl + 'amperage-check';
    private token = '';
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.token = auth.getToken();
        this.headers = new HttpHeaders({Authorization: this.token});
    }

    getAllStandBy() {
        const headers = this.headers;
        const url = this.expellersUrl + `/standby`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    getAllActive() {
        const headers = this.headers;
        const url = this.expellersUrl + `/active`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    getAll() {
        const headers = this.headers;
        const url = this.expellersUrl + `/all`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    startProcess(body: any): Promise<any> {
        const headers = this.headers;
        const url = this.expellersUrl + `/start`;
        return this.http.post(url, body, {headers})
            .toPromise();
    }

    addCookerToExpeller(body: any, expellerMoveID): Promise<any> {
        const headers = this.headers;
        const url = this.expellersUrl + `/add/${expellerMoveID}`;
        return this.http.put(url, body, {headers})
            .toPromise();
    }

    finishProcess(body: any): Promise<any> {
        const headers = this.headers;
        const url = this.expellersUrl + `/finish`;
        return this.http.put(url, body, {headers})
            .toPromise();
    }

    registerAmperage(body: any): Promise<any> {
        const headers = this.headers;
        const url = this.amperageUrl;
        return this.http.post(url, body, {headers})
            .toPromise();
    }

    getAllFinished() {
        const headers = this.headers;
        const url = this.expellersUrl + `/finished`;
        return this.http.get(url, {headers})
            .toPromise();
    }
}
