import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TanksService {
  private url = environment.apiUrl;
  private token = '';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.token = auth.getToken();
    this.headers = new HttpHeaders({ Authorization: this.token });
  }

  getAllTanks() {
    const headers = this.headers;
    const url = this.url + `storage-tanks`;
    return this.http.get(url, { headers })
    .toPromise();
  }
}
