import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ExpellersService} from '../../services/expellers.service';
import {UntypedFormControl} from '@angular/forms';
import {HoppersService} from '../../../grinding/services/hoppers.service';

@Component({
    selector: 'app-finish-process',
    templateUrl: './finish-process.component.html',
    styles: []
})
export class FinishProcessComponent implements OnInit {

    now = new Date();
    move: any;
    remarks = new UntypedFormControl(null);
    hopper = new UntypedFormControl(null);
    loading = false;
    hoppers: any[] = [];

    constructor(
        private router: Router,
        private expellersService: ExpellersService,
        private hoppersService: HoppersService
    ) {
        if (!history.state.move) {
            router.navigate(['/expellers/procesos']);
        }
        setInterval(() => {
            this.now = new Date();
        }, 1000);
    }

    ngOnInit() {
        this.loading = true;
        this.hoppersService.getAllHoppers().then(response => {
            this.move = history.state.move;
            this.hoppers = response['data'];
            this.loading = false;
        });
    }

    finish() {
        this.loading = true;
        const body = {
            movement_id: this.move.movement_id,
            remarks: this.remarks.value,
            hopper: this.hopper.value
        };
        this.expellersService.finishProcess(body).then(resp => {
            this.router.navigate(['/expellers/procesos']);
        });
    }

    cancel() {
        this.router.navigate(['/expellers/procesos']);
    }

}
