import { Component, OnInit } from '@angular/core';
import { AdditivesService } from '../../../services/additives.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-additives-end-select-shift',
  templateUrl: './additives-end-select-shift.component.html',
  styles: []
})
export class AdditivesEndSelectShiftComponent implements OnInit {

  checks = [];
  loading = false;

  constructor(
    private additivesService: AdditivesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.additivesService.getActiveChecks().then(resp => {
      this.checks = resp['data'];
      this.loading = false;
    });
  }

  select(check) {
    this.router.navigate(['/molienda/procesos/aditivos/fin/confirmar'], {state: {check}});
  }

}
