import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductTypesService } from 'src/app/production/services/product-types.service';

@Component({
  selector: 'app-select-product-start',
  templateUrl: './select-product-start.component.html',
  styles: []
})
export class SelectProductStartComponent implements OnInit {

  types = [];
  shift: any;
  loading = false;

  constructor(
    private router: Router,
    private productTypesService: ProductTypesService
  ) {
    if (!history.state.shift) {
      router.navigate(['/periodos/checkpoint']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.shift = history.state.shift;
    this.productTypesService.getAll().then(resp => {
      this.types = resp as any;
      this.loading = false;
    });
  }

  select(type) {
    const data = {
      shift: this.shift,
      type
    };
    this.router.navigate(['/periodos/iniciar/confirmar'], {state: {data}});
  }

}
