<body class="horizontal-layout horizontal-menu 2-columns footer-static" data-open="hover" data-menu="horizontal-menu" data-col="2-columns">
    <app-navbar></app-navbar>

    <router-outlet></router-outlet>

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <app-footer></app-footer>

</body>
