import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [':host zxing-scanner::ng-deep video { max-height:11.7em; width:100%; object-fit: none;}']
})
export class LoginComponent implements OnInit {

  enable = true;
  title = 'PREHOGOR';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  showSmartCard(userData) {
    this.router.navigate(['/prehogor/smartcard'], {state: {userData}});
  }

}
