import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WarehousesService } from '../../../services/warehouses.service';
import { WarehouseChecksService } from 'src/app/grinding/services/warehouse-checks.service';

@Component({
  selector: 'app-warehouse-confirm-check',
  templateUrl: './warehouse-confirm-check.component.html',
  styles: []
})
export class WarehouseConfirmCheckComponent implements OnInit {

  shift: any;
  now = new Date();
  form: UntypedFormGroup;
  checks = new UntypedFormArray([]);
  warehouses = [];
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private warehousesService: WarehousesService,
    private warehouseChecksService: WarehouseChecksService
  ) {
    if (!history.state.shift) {
      router.navigate(['/molienda/procesos']);
    }
    this.buildForm();
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.loading = true;
    this.shift = history.state.shift;
    this.warehousesService.getAllWarehouses().then(resp => {
      this.checks.clear();
      this.warehouses = resp['data'];
      this.warehouses.forEach(w => {
        this.checks.push(this.formBuilder.group({
          temperature: [null, Validators.required],
          humidity: [null, Validators.required],
          warehouse: [w.warehouse_id]
        }));
      });
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      checks: this.formBuilder.array([this.createCheck()])
    });

    this.checks = this.form.get('checks') as UntypedFormArray;
  }

  createCheck() {
    return this.formBuilder.group({
      temperature: [null, Validators.required],
      humidity: [null, Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const checks = [];
      for (let i = 0; i < this.checks.length; i++) {
        const c = this.checks.at(i);
        checks.push({
          warehouse_id: c.value.warehouse,
          temperature: c.value.temperature,
          humidity: c.value.humidity,
        });
      }

      const body = {
        shift_id: this.shift.shift_id,
        checks: JSON.stringify(checks)
      };
      this.warehouseChecksService.registerChecks(body).then(resp => {
        this.router.navigate(['/molienda/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }
}
