import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookersService } from '../../services/cookers.service';

@Component({
  selector: 'app-finish-select-cooker',
  templateUrl: './finish-select-cooker.component.html',
  styles: []
})
export class FinishSelectCookerComponent implements OnInit {

  moves: any;
  loading = false;

  constructor(
    private router: Router,
    private cookersService: CookersService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.cookersService.getAllStartedProcesses(true).then(resp => {
      this.moves = resp as any;
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/cocedores', 'terminar', 'confirmar'], {state: {move}});
  }

}
