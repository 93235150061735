import {Component, OnInit} from '@angular/core';
import {ShakersService} from '../../../services/shakers.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-select-move-add-tallow',
    templateUrl: './select-move-add-tallow.component.html',
    styles: []
})
export class SelectMoveAddTallowComponent implements OnInit {

    moves = [];
    loading = false;

    constructor(
        private shakersService: ShakersService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.shakersService.getAllActiveShakers().then(resp => {
            this.moves = resp as any;
            this.loading = false;
        });
    }

    select(move) {
        const data = {
            move
        };
        this.router.navigate(['/tanques/procesos/centrifuga/agregar/cocedor'], {state: {data}});
    }

}
