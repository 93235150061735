<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> INGRESAR AMPERES DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <!-- PRODUCTO ASIGNADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            PRODUCTO ASIGNADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.move.period.product.short_name}} ({{data.move.period.product.full_name}})" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-pie-chart"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //PRODUCTO ASIGNADO -->

                                    <!-- EXPELLER SELECCIONADO -->
<!--                                    <div class="col-sm-12 col-md-12">-->
<!--                                        <div class="text-bold-600 font-medium-2 mb-1">-->
<!--                                            EXPELLER SELECCIONADO-->
<!--                                        </div>-->
<!--                                        <fieldset class="form-group position-relative has-icon-left">-->
<!--                                            <input type="text" class="form-control" value="{{data.move.expeller.expeller_key}}" disabled="">-->
<!--                                            <div class="form-control-position">-->
<!--                                                <i class="fa fa-industry"></i>-->
<!--                                            </div>-->
<!--                                        </fieldset>-->
<!--                                    </div>-->
                                    <!-- //EXPELLER SELECCIONADO -->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            COCEDOR SELECCIONADO
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.cooker.cooker.cooker_key}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            PROCEDENCIA
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{data.cooker.origins}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-industry"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <!-- TIEMPO REGISTRADO -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="text-bold-600 font-medium-2 mb-1">
                                            HORA DE REVISIÓN
                                        </div>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" class="form-control" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                            <div class="form-control-position">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!-- //TIEMPO REGISTRADO -->

                                </div>
                            </div>
                        </div>
                        <button (click)="register()" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                        <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
