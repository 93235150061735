import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AntioxidantService } from '../../../services/antioxidant.service';

@Component({
  selector: 'app-antioxidant-end-confirm',
  templateUrl: './antioxidant-end-confirm.component.html',
  styles: []
})
export class AntioxidantEndConfirmComponent implements OnInit {

  check: any;
  now = new Date();
  antioxidant = new UntypedFormControl(null, Validators.required);
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private router: Router,
    private antioxidantService: AntioxidantService
  ) {
    if (!history.state.check) {
      router.navigate(['/tanques/procesos']);
    }

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.check = history.state.check;
  }

  cancel() {
    this.router.navigate(['/tanques/procesos']);
  }

  submit() {
    if (this.antioxidant.valid) {
      this.loading = true;
      const body = {
        antioxidant: this.antioxidant.value,
        remarks: this.remarks.value
      };
      this.antioxidantService.registerEndCheck(this.check.check_id, body).then(resp => {
        this.router.navigate(['/tanques/procesos']);
      });
    }
  }

}
