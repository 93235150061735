import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GrindingService } from '../../../services/grinding.service';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-grinding-confirm-finish',
  templateUrl: './grinding-confirm-finish.component.html',
  styles: []
})
export class GrindingConfirmFinishComponent implements OnInit {

  move: any;
  now = new Date();
  obtainedSacks = new UntypedFormControl(null, Validators.required);
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private router: Router,
    private grindingService: GrindingService
  ) {
    if (!history.state.move) {
      router.navigate(['/molienda/procesos']);
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  submit() {
    if (this.obtainedSacks.valid) {
      this.loading = true;
      const body = {
        obtained_sacks: this.obtainedSacks.value,
        remarks: this.remarks.value
      };

      this.grindingService.finishMovement(this.move.movement_id, body).then(resp => {
        this.router.navigate(['/molienda/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

}
