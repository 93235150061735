import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnloadRoutingModule } from './unload-routing.module';
import { UnloadLoginComponent } from './components/unload-login/unload-login.component';
import { UnloadMainMenuComponent } from './components/unload-main-menu/unload-main-menu.component';
import { UnloadDetectedUserComponent } from './components/unload-detected-user/unload-detected-user.component';
import { UnloadDetailComponent } from './components/unload-detail/unload-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';


@NgModule({
  declarations: [UnloadLoginComponent, UnloadMainMenuComponent, UnloadDetectedUserComponent, UnloadDetailComponent],
  imports: [
    CommonModule,
    UnloadRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class UnloadModule { }
