import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TanksService} from '../../../../filtering/services/tanks.service';
import {ShakersService} from '../../../services/shakers.service';

@Component({
    selector: 'app-confirm-weighing',
    templateUrl: './confirm-weighing.component.html',
    styles: []
})
export class ConfirmWeighingComponent implements OnInit {

    move: any;
    now = new Date();
    tanks = [];
    tanksArr = new UntypedFormArray([]);
    form: UntypedFormGroup;
    loading = false;

    constructor(
        private router: Router,
        private tanksService: TanksService,
        private formBuilder: UntypedFormBuilder,
        private shakersService: ShakersService
    ) {
        if (!history.state.move) {
            router.navigate(['/tanques/procesos']);
        }
        this.buildForm();
        setInterval(() => this.now = new Date(), 1000);
    }

    ngOnInit() {
        this.loading = true;
        this.move = history.state.move;
        for (const deposit of this.move.storage_tanks_deposits) {
          this.tanksArr.push(this.formBuilder.group({
              moveId: deposit.movement_id,
              tankId: deposit.tank.tank_id,
              volume: [null, Validators.required]
          }));
        }
        this.loading = false;
    }

    buildForm() {
        this.form = this.formBuilder.group({
            decanterFlour: [null, Validators.required],
            tallowVolume: [null, Validators.required],
            remarks: [null],
            tanks: this.formBuilder.array([]),
        });
        this.tanksArr = this.form.get('tanks') as UntypedFormArray;
    }

    submit() {
        if (this.form.valid) {
            this.loading = true;

            const tanks = this.tanksArr.getRawValue();

            const body = {
                tallow_volume: this.form.value.tallowVolume,
                decanter_flour: this.form.value.decanterFlour,
                remarks: this.form.value.remarks,
                tallow_moves: tanks,
            };

            this.shakersService.registerWeight(this.move.movement_id, body).then(resp => {
                this.router.navigate(['/tanques/procesos']);
            });
        }
    }

    cancel() {
        this.router.navigate(['/tanques/procesos'], {replaceUrl: true});
    }

}
