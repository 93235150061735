import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { WeighingModule } from './weighing/weighing.module';
import { UnloadModule } from './unload/unload.module';
import { ProductionModule } from './production/production.module';
import { ExpellersModule } from './expellers/expellers.module';
import { CookersModule } from './cookers/cookers.module';
import { PrehogorModule } from './prehogor/prehogor.module';
import { TanksModule } from './tanks/tanks.module';
import { FilteringModule } from './filtering/filtering.module';
import { GrindingModule } from './grinding/grinding.module';
import { PeriodModule } from './period/period.module';
import { NgxLoadingModule } from 'ngx-loading';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

const routes: Routes = [
  // { path: '', redirectTo: '/descarga', pathMatch: 'full'},
  // { path: '**', redirectTo: '/descarga', pathMatch: 'full'},
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    LoginModule,
    WeighingModule,
    UnloadModule,
    ProductionModule,
    CookersModule,
    PrehogorModule,
    ExpellersModule,
    TanksModule,
    FilteringModule,
    GrindingModule,
    PeriodModule,
    NgxLoadingModule.forRoot({fullScreenBackdrop: true}),
    SweetAlert2Module.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
