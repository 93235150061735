import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unload-login',
  templateUrl: './unload-login.component.html',
  styles: [':host zxing-scanner::ng-deep video { max-height:15em; object-fit: none;}']
})
export class UnloadLoginComponent implements OnInit {

  title = 'DESCARGA';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  showSmartCard(userData) {
    this.router.navigate(['/descarga/smartcard'], {state: {userData}});
  }

}
