import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductTypesService } from 'src/app/production/services/product-types.service';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styles: []
})
export class SelectProductComponent implements OnInit {

  types = [];
  data: any;

  constructor(
    private productTypesService: ProductTypesService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/cocedores', 'procesos']);
    }
  }

  ngOnInit() {
    this.data = history.state.data;
    this.productTypesService.getAll().then(resp => {
      this.types = resp as Array<any>;
    });
  }

  select(type) {
    this.data.type = type;

    this.router.navigate(['/cocedores', 'iniciar', 'confirmar'], {state: {data: this.data}});
  }

}
