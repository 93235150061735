<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">

            <!-- DATOS DE RUTAS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- UNIDAD DISPONIBLE -->
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-success text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-success shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="mb-2 text-white">INGRESO DE UNIDADES</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['/bascula/ingreso']" type="button" class="btn btn-lg btn-relief-success mr-1 mb-2 waves-effect waves-light">REGISTRAR INGRESO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //UNIDAD DISPONIBLE -->


                    <!-- UNIDAD TERMINADA -->
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-danger text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-danger shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-sign-out font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="mb-2 text-white">SALIDA DE UNIDADES</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['/bascula/salida']" type="button" class="btn btn-lg btn-relief-danger mr-1 mb-2 waves-effect waves-light">REGISTRAR SALIDA</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //RUTA TERMINADA -->

                </div>
            </section>
            <!-- //DATOS DE RUTA DISPONIBLE -->

        </div>
    </div>
</div>