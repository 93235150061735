import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookersService } from '../../services/cookers.service';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-confirm-start',
  templateUrl: './confirm-start.component.html',
  styles: []
})
export class ConfirmStartComponent implements OnInit {

  data: any;
  now = new Date();
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cookersService: CookersService
  ) {
    if (!history.state.data) {
      router.navigate(['/cocedores', 'checkpoint']);
    }
    setInterval(() => this.now = new Date(), 1000);
    this._buildForm();
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  private _buildForm() {
      this.form = this.formBuilder.group({
          added_decanter_flour: [null, Validators.required],
          remarks: [null],
          temperature: [null, Validators.required],
          pressure: [null, Validators.required],
      });
  }

  startProcess() {

    if (this.form.valid) {
      this.loading = true;

      this.cookersService.startProcess(this.data.move.movement_id, this.form.value).then(resp => {
        this.router.navigate(['/cocedores/procesos']);
      });
    }

  }

}
