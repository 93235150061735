import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ShakersService} from '../../../services/shakers.service';

@Component({
    selector: 'app-select-cooker-add-tallow',
    templateUrl: './select-cooker-add-tallow.component.html',
    styles: []
})
export class SelectCookerAddTallowComponent implements OnInit {
    cookers = [];
    data: any;
    loading = false;

    constructor(
        private router: Router,
        private shakersService: ShakersService
    ) {
        if (!history.state.data) {
            router.navigate(['/tanques/procesos']);
        }
    }

    ngOnInit() {
        this.loading = true;
        this.data = history.state.data;
        this.shakersService.getValidatedCookerMoves().then(resp => {
            this.cookers = resp['data'];
            this.loading = false;
        });
    }

    select(cooker) {
        this.data.cooker = cooker;
        this.router.navigate(['/tanques', 'procesos', 'centrifuga', 'agregar', 'confirmar'], {state: {data: this.data}});
    }

}
