import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [NavbarComponent, FooterComponent, MainComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([])
  ],
  exports: [
    MainComponent,
  ]
})
export class LayoutModule { }
