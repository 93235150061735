import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExpellersService } from '../../services/expellers.service';
import {CookersService} from '../../../cookers/services/cookers.service';

@Component({
  selector: 'app-select-available',
  templateUrl: './select-available.component.html',
  styles: []
})
export class SelectAvailableComponent implements OnInit {

  cookerMoves = [];
  data: any;
  loading = false;

  constructor(
    private router: Router,
    private cookersService: CookersService,
  ) {
    if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.cookersService.getMovesByExpellerMoveId(this.data.move.movement_id).then(resp => {
      this.cookerMoves = resp['data'] as any;
      this.loading = false;
    });
  }

  select(cooker) {
    this.data.cooker = cooker;
    this.router.navigate(['/expellers', 'procesos', 'expeller', 'amperaje', 'registrar'], {state: {data: this.data}});
  }

}
