import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MagnetsService } from '../../../services/magnets.service';

@Component({
  selector: 'app-magnets-select',
  templateUrl: './magnets-select.component.html',
  styles: []
})
export class MagnetsSelectComponent implements OnInit {

  magnets = [];
  // data: any;
  loading = false;

  constructor(
    private router: Router,
    private magnetsService: MagnetsService
  ) {
    /*if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }*/
  }

  ngOnInit() {
    this.loading = true;
    // this.data = history.state.data;
    this.magnetsService.getAll().then(resp => {
      this.magnets = resp as any;
      this.loading = false;
    });
  }

  select(magnet) {
    // this.data.magnet = magnet;
    this.router.navigate(['/expellers', 'procesos', 'imanes', 'registrar'], {state: {data: {magnet}}});
  }

}
