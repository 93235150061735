<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> Mostrando Unidades por Descargar
            </div>
            <!-- DATOS DE RUTAS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- RUTA DISPONIBLE -->
                    <div *ngFor="let i of runs.internal" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-truck font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="text-white">{{i.route.route_key}}</h1>
                                        <!-- ID DE UNIDAD -->
                                        <p class="card-text">{{i.vehicle.vehicle_key}}</p>
                                        <!-- OPERADOR DE LA UNIDAD -->
                                        <p class="card-text">{{i.operator.username}}</p>
                                        <!-- PESO DE BÁSCULA EN INGRESO -->
                                        <p class="card-text">{{i.first_weight|number}} kg</p>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="detail(i)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">SELECCIONAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let e of runs.external" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-truck font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="text-white">{{e.origin_city}} (Externo)</h1>
                                        <!-- ID DE UNIDAD -->
                                        <p class="card-text">{{e.vehicle_key}}</p>
                                        <!-- OPERADOR DE LA UNIDAD -->
                                        <p class="card-text">{{e.operator}}</p>
                                        <!-- PESO DE BÁSCULA EN INGRESO -->
                                        <p class="card-text">{{e.first_weight|number}} kg</p>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="detail(e, true)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">SELECCIONAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //RUTA DISPONIBLE -->

                </div>
            </section>
            <!-- //DATOS DE RUTA DISPONIBLE -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
