import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-weighing-login',
  templateUrl: './weighing-login.component.html',
  styles: [':host zxing-scanner::ng-deep video { max-height:15em; object-fit: none;}']
})
export class WeighingLoginComponent implements OnInit {

  title = 'BÁSCULA';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  showSmartCard(userData) {
    this.router.navigate(['/bascula/smartcard'], {state: {userData}});
  }

}
