import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { WeighService } from '../../../services/weigh.service';
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-entry-weighing',
  templateUrl: './entry-weighing.component.html',
  styles: []
})
export class EntryWeighingComponent implements OnInit {

  run = {
    run_id: null,
    quantity: null
  };
  isExternal: boolean;
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private weighService: WeighService,
    public readonly swalTargets: SwalPortalTargets
  ) {
    if (!history.state.run) {
      router.navigate(['/bascula/ingreso']);
    }

    this.buildForm();
  }

  ngOnInit() {
    this.run = history.state.run;
    this.isExternal = history.state.isExternal;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      weighedQuantity: [null, Validators.required],
      prehogor: [null, Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        weighed_quantity: this.form.value.weighedQuantity,
        prehogor: this.form.value.prehogor,
      };
      if (this.isExternal) {
        this.weighService.externalEntryWeigh(this.run.run_id, body).then(resp => {
          this.router.navigate(['/bascula/inicio'], {replaceUrl: true});
        });
      } else  {
        this.weighService.entryWeigh(this.run.run_id, body).then(resp => {
          this.router.navigate(['/bascula/inicio'], {replaceUrl: true});
        });
      }
    }
  }

}
