import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MagnetCleaningService {
  private url = environment.apiUrl;
  private token = '';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.token = auth.getToken();
    this.headers = new HttpHeaders({ Authorization: this.token });
  }

  addCleaning(body) {
    const headers = this.headers;
    const url = this.url + `magnet-cleaning`;
    return this.http.post(url, body, { headers })
    .toPromise();
  }
}
