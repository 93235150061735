import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MagnetCleaningService } from 'src/app/expellers/services/magnet-cleaning.service';
import { DestinationsService } from 'src/app/grinding/services/destinations.service';

@Component({
  selector: 'app-register-cleaning',
  templateUrl: './register-cleaning.component.html',
  styles: []
})
export class RegisterCleaningComponent implements OnInit {

  data: any;
  now = new Date();
  form: UntypedFormGroup;
  dests = [];
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private magnetCleaningService: MagnetCleaningService,
    private destinationsService: DestinationsService,
  ) {
    if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }

    this.buildForm();

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.destinationsService.getAllDestinations().then(resp => {
      this.dests = resp['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      withdrawalAmount: [null, Validators.required],
      screws: [true],
      wires: [true],
      metals: [true],
      burr: [true],
      destination: [null, Validators.required],
      remarks: [null]
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        // period_id: this.data.period.period_id,
        // expeller_id: this.data.expeller.expeller_id,
        magnet_id: this.data.magnet.magnet_id,
        withdrawal_amount: this.form.get('withdrawalAmount').value,
        screws: this.form.get('screws').value ? 1 : 0,
        wires: this.form.get('wires').value ? 1 : 0,
        metals: this.form.get('metals').value ? 1 : 0,
        burr: this.form.get('burr').value ? 1 : 0,
        destination_id: this.form.value.destination,
        remarks: this.form.get('remarks').value
      };
      this.magnetCleaningService.addCleaning(body).then(resp => {
        this.router.navigate(['/expellers/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/expellers/procesos']);
  }

}
