import { Component, OnInit } from '@angular/core';
import { ProductTypesService } from 'src/app/production/services/product-types.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expellers-product-type-select',
  templateUrl: './expellers-product-type-select.component.html',
  styles: []
})
export class ExpellersProductTypeSelectComponent implements OnInit {

  types = [];

  constructor(
    private productTypesService: ProductTypesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.productTypesService.getAll().then(resp => {
      this.types = resp as Array<any>;
    });
  }

  select(type) {
    this.router.navigate(['/expellers', 'procesos', 'expeller', 'cocedores'], {state: {type}});
  }

}
