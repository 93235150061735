import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class WeighService {

  url = environment.apiUrl + 'traffic';
  token = '';
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.token = auth.getToken();
    this.headers = new HttpHeaders({ Authorization: this.token });
  }

  getEnteringVehicles() {
    const headers = this.headers;
    return this.http.get(this.url + `/entry/vehicles`, { headers })
    .toPromise();
  }

  getDepartingVehicles() {
    const headers = this.headers;
    return this.http.get(this.url + `/departure/vehicles`, { headers })
    .toPromise();
  }

  entryWeigh(runID, data) {
    const headers = this.headers;
    return this.http.post(this.url + `/weigh/${runID}/entry`, data, { headers })
    .toPromise();
  }

  departureWeigh(runID, data) {
    const headers = this.headers;
    return this.http.post(this.url + `/weigh/${runID}/departure`, data, { headers })
    .toPromise();
  }

  externalEntryWeigh(runID, data) {
    const headers = this.headers;
    return this.http.post(this.url + `/external/weigh/${runID}/entry`, data, { headers })
    .toPromise();
  }

  externalDepartureWeigh(runID, data) {
    const headers = this.headers;
    return this.http.post(this.url + `/external/weigh/${runID}/departure`, data, { headers })
    .toPromise();
  }

}
