import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PeriodRoutingModule } from './period-routing.module';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { SelectShiftStartComponent } from './components/select-shift-start/select-shift-start.component';
import { SelectProductStartComponent } from './components/select-product-start/select-product-start.component';
import { ConfirmStartComponent } from './components/confirm-start/confirm-start.component';
import { SelectPeriodFinishComponent } from './components/select-period-finish/select-period-finish.component';
import { ConfirmFinishComponent } from './components/confirm-finish/confirm-finish.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';
import { EditPeriodSelectPeriodComponent } from './components/edit-period-select-period/edit-period-select-period.component';
import { EditPeriodConfirmComponent } from './components/edit-period-confirm/edit-period-confirm.component';
import { LastClosedPeriodsComponent } from './components/last-closed-periods/last-closed-periods.component';
import { ReopenPeriodComponent } from './components/reopen-period/reopen-period.component';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, CheckpointComponent, SelectShiftStartComponent, SelectProductStartComponent, ConfirmStartComponent, SelectPeriodFinishComponent, ConfirmFinishComponent, EditPeriodSelectPeriodComponent, EditPeriodConfirmComponent, LastClosedPeriodsComponent, ReopenPeriodComponent],
  imports: [
    CommonModule,
    PeriodRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class PeriodModule { }
