import { Component, OnInit } from '@angular/core';
import { WorkShiftsService } from 'src/app/production/services/work-shifts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-antioxidant-start-select-shift',
  templateUrl: './antioxidant-start-select-shift.component.html',
  styles: []
})
export class AntioxidantStartSelectShiftComponent implements OnInit {

  shifts = [];
  loading = false;

  constructor(
    private workShiftsService: WorkShiftsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.workShiftsService.getAll().then(resp => {
      this.shifts = resp['data'];
      this.loading = false;
    });
  }

  select(shift) {
    this.router.navigate(['/tanques/procesos/antioxidante/inicio/confirmar'], {state: {shift}});
  }

}
