import { Component, OnInit } from '@angular/core';
import { OriginService } from 'src/app/prehogor/services/origin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookers-select-origin',
  templateUrl: './cookers-select-origin.component.html',
  styles: []
})
export class CookersSelectOriginComponent implements OnInit {

  data = {
    internal: [],
    external: []
  };

  constructor(
    private originService: OriginService,
    private router: Router
  ) {}

  ngOnInit() {
    this.originService.getOrigins().then(resp => {
      this.data = resp as any;
    });
  }

  selectInternal(r: any) {
    const origin = r.route.territory;
    this.navigate(origin);
  }

  selectExternal(r: any) {
    const origin = r.origin_city;
    this.navigate(origin);
  }

  navigate(origin: any) {
    this.router.navigate(['/cocedores', 'iniciar', 'disponibles'], {state: {origin}});
  }

}
