import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrehogorService } from '../../services/prehogor.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styles: []
})
export class StartComponent implements OnInit {

  data: any;
  now = new Date();
  amount = new UntypedFormControl(null, Validators.required);
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private prehogorService: PrehogorService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/prehogor/procesos']);
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  start() {
    if (this.amount.valid && this.amount.value <= this.data.run.available) {
      this.loading = true;
      const body: any = {
        periodID: this.data.period.period_id,
        isExternalRun: this.data.isExternalRun,
        runID: this.data.run.runID,
        amount: this.amount.value,
        remarks: this.remarks.value
      };

      if (this.data.isCooker === true) {
        body.cookerID = this.data.cooker.cooker_id;
      } else {
        body.cookerMovementID = this.data.cooker.movement_id;
      }

      this.prehogorService.startLoading(body).then(resp => {
        this.router.navigate(['/prehogor/procesos']);
      }).catch(err => {
        console.log(err);
      });
    } else {
        Swal.fire({
            titleText: 'Error',
            text: 'La cantidad de materia prima que ingresó no debe ser mayor a los kilogramos disponibles',
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
        }).then();
    }
  }

  cancel() {
    this.router.navigate(['/prehogor/procesos']);
  }

}
