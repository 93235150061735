import { Component, OnInit } from '@angular/core';
import { GrindersService } from 'src/app/grinding/services/grinders.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grinding-select-grinder',
  templateUrl: './grinding-select-grinder.component.html',
  styles: []
})
export class GrindingSelectGrinderComponent implements OnInit {

  grinders = [];
  shift: any;

  constructor(
    private grindersService: GrindersService,
    private router: Router
  ) {
    if (!history.state.shift) {
      router.navigate(['/molienda/procesos']);
    }
  }

  ngOnInit() {
    this.shift = history.state.shift;
    this.grindersService.getAllGrinders().then(resp => {
      this.grinders = resp['data'];
    });
  }

  select(grinder: any) {
    const data = {
      shift: this.shift,
      grinder
    };
    this.router.navigate(['/molienda/procesos/molienda/iniciar/pasta'], {state: {data}});
  }

}
