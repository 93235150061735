<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR EXPELLER ACTIVO
            </div>
            <!-- DATOS DE EXPELLERS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- EXPELLER DISPONIBLE -->
                    <div *ngFor="let m of moves" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE EXPELLER -->
                                        <h4 class="mb-2 text-white">{{m.period.product.full_name}} ({{m.period.product.short_name}})</h4>
                                        <h5 class="mb-2 text-white">{{m.operationTime|number}} min.</h5>
                                        <h6 class="mb-2 text-white">{{m.period.shift.name}}</h6>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="select(m)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">SELECCIONAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //EXPELLER DISPONIBLE -->
                </div>
            </section>
            <!-- //DATOS DE EXPELLERS DISPONIBLES -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
