<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR TURNO
            </div>
            <!-- DATOS DE REACTORES DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- MOLINO DISPONIBLE -->
                    <div *ngFor="let s of shifts" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE REACTORES -->
                                        <h1 class="mb-2 text-white">{{s.name}}</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="select(s)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //MOLINO DISPONIBLE -->

                </div>
            </section>
            <!-- //DATOS DE REACTORES DISPONIBLES -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
