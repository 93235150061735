import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-departing-vehicle-detail',
  templateUrl: './departing-vehicle-detail.component.html',
  styles: []
})
export class DepartingVehicleDetailComponent implements OnInit {

  vehicle = {
    vehicle_key: '',
    license_plate: '',
    brand: ''
  };

  run: any;
  isExternal: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    if (!history.state.run) {
      router.navigate(['/bascula/salida'], { replaceUrl: true });
    }
  }

  ngOnInit() {
    this.run = history.state.run;
    this.isExternal = history.state.isExternal;
    this.vehicle = this.run.vehicle;
  }

  weigh() {
    this.router.navigate(['pesar'], { relativeTo: this.route, state: {run: this.run, isExternal: this.isExternal}});
  }

  back() {
    this.location.back();
  }

}
