import { Component, OnInit } from '@angular/core';
import { OriginService } from '../../services/origin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-origin',
  templateUrl: './select-origin.component.html',
  styles: []
})
export class SelectOriginComponent implements OnInit {

  data = {
    internal: [],
    external: []
  };
  loading = false;

  constructor(
    private originService: OriginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.originService.getOrigins().then(resp => {
      this.data = resp as any;
      this.loading = false;
    });
  }

  selectInternal(r: any) {
    const run = {
      runID: r.run_id,
      origin: r.route.route_key,
      vehicle: r.vehicle.vehicle_key,
      available: r.quantity_left,
      unloadStarted: r.unload_started_at,
    };
    this.navigate(run, false);
  }

  selectExternal(r: any) {
    const run = {
      runID: r.run_id,
      origin: r.origin_city,
      vehicle: r.vehicle_key,
      available: r.quantity_left,
      unloadStarted: r.unload_started_at,
    };
    this.navigate(run, true);
  }

  navigate(run: any, isExternalRun: boolean) {
    const data = { run, isExternalRun };
    this.router.navigate(['/prehogor', 'iniciar', 'periodo'], {state: {data}});
  }

}
