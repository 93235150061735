import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AuthService} from 'src/app/login/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MovementService {
    private url = environment.apiUrl;
    private token = '';
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.token = auth.getToken();
        this.headers = new HttpHeaders({Authorization: this.token});
    }

    getShakerMoves() {
        const headers = this.headers;
        const url = this.url + `shakers/finished`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    startMovement(body: any) {
        const headers = this.headers;
        const url = this.url + `filter-press/additives`;
        return this.http.post(url, body, {headers})
            .toPromise();
    }

    getWaitingShakingMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/shaking/waiting`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    getRejectedMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/rejected`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    updateAdditives(moveId: any, body: any) {
        const headers = this.headers;
        const url = this.url + `filter-press/additives/rework/${moveId}`;
        return this.http.post(url, body, {headers})
            .toPromise();
    }

    startShaking(movementID) {
        const headers = this.headers;
        const url = this.url + `filter-press/shaking/start/${movementID}`;
        return this.http.put(url, null, {headers})
            .toPromise();
    }

    getShakingMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/shaking/in-process`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    finishShaking(movementID) {
        const headers = this.headers;
        const url = this.url + `filter-press/shaking/finish/${movementID}`;
        return this.http.put(url, null, {headers})
            .toPromise();
    }

    getWaitingFilteringMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/filtering/waiting`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    startFiltering(movementID, body) {
        const headers = this.headers;
        const url = this.url + `filter-press/filtering/start/${movementID}`;
        return this.http.put(url, body, {headers})
            .toPromise();
    }

    getFilteringMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/filtering/in-process`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    finishFiltering(movementID, body) {
        const headers = this.headers;
        const url = this.url + `filter-press/filtering/finish/${movementID}`;
        return this.http.put(url, body, {headers})
            .toPromise();
    }

    getWaitingCleaningMovements() {
        const headers = this.headers;
        const url = this.url + `filter-press/cleaning/waiting`;
        return this.http.get(url, {headers})
            .toPromise();
    }

    registerCleaning(movementID, body) {
        const headers = this.headers;
        const url = this.url + `filter-press/cleaning/${movementID}`;
        return this.http.put(url, body, {headers})
            .toPromise();
    }
}
