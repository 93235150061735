import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookersService } from 'src/app/cookers/services/cookers.service';

@Component({
  selector: 'app-expellers-select-cooker',
  templateUrl: './expellers-select-cooker.component.html',
  styles: []
})
export class ExpellersSelectCookerComponent implements OnInit {

  cookers = [];
  data: any;
  loading = false;

  constructor(
    private router: Router,
    private cookersService: CookersService
  ) {
    if (!history.state.data) {
      router.navigate(['/expellers/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.cookersService.getFinishedMoves(this.data.move.period_id).then(resp => {
      this.cookers = resp['data'];
      this.loading = false;
    });
  }

  select(cooker) {
    this.data.cooker = cooker;
    this.router.navigate(['/expellers', 'procesos', 'expeller', 'agregar', 'confirmar'], {state: {data: this.data}});
  }

}
