import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = environment.apiUrl;

  userToken = '';
  expirationTime = 0;

  constructor(private http: HttpClient) {
    this.getToken();
  }

  signIn(data: any) {

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'signin', data, {headers})
    .pipe(
      map(resp => {
        this.setLoginInfo(resp);
        return resp;
      })
    ).toPromise();
  }

  signInByHash(nip: any, expeller = false) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    let url = this.url + 'signin?hash=true';
    if (expeller === true) {
      url += '&expeller=true';
    }

    return this.http.post(url, {nip}, {headers})
    .pipe(
      map(resp => {
        this.setLoginInfo(resp);
        return resp;
      })
    ).toPromise();
  }

  private setLoginInfo(info) {
    this.userToken = info['access_token'];
    localStorage.setItem('token', info['access_token']);

    const now = new Date();
    now.setSeconds(Number(info['expiration_time']));

    localStorage.setItem('expirationTime', now.getTime().toString());

    localStorage.setItem('role', info['role_id']);

    localStorage.setItem('name', info['name']);
    localStorage.setItem('fullName', info['full_name']);
  }

  getToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  isLoggedIn(): boolean {
    if (this.userToken.length < 2) {
      return false;
    }

    const expirationTime = Number(localStorage.getItem('expirationTime'));
    const expirationDate = new Date();
    expirationDate.setTime(expirationTime);

    if (expirationDate > new Date()) {
      return true;
    }

    return false;
  }
}
