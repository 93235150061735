import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TanksRoutingModule } from './tanks-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { CentrifugeComponent } from './components/centrifuge/centrifuge.component';
import { StartComponent } from './components/centrifuge/start/start.component';
import { ConfirmStartComponent } from './components/centrifuge/confirm-start/confirm-start.component';
import { FinishComponent } from './components/centrifuge/finish/finish.component';
import { ConfirmFinishComponent } from './components/centrifuge/confirm-finish/confirm-finish.component';
import { StartSelectPeriodComponent } from './components/centrifuge/start-select-period/start-select-period.component';
import { AntioxidantStartSelectShiftComponent } from './components/antioxidant/antioxidant-start-select-shift/antioxidant-start-select-shift.component';
import { AntioxidantEndSelectShiftComponent } from './components/antioxidant/antioxidant-end-select-shift/antioxidant-end-select-shift.component';
import { AntioxidantEndConfirmComponent } from './components/antioxidant/antioxidant-end-confirm/antioxidant-end-confirm.component';
import { AntioxidantStartConfirmComponent } from './components/antioxidant/antioxidant-start-confirm/antioxidant-start-confirm.component';
import { AntioxidantCheckpointComponent } from './components/antioxidant/antioxidant-checkpoint/antioxidant-checkpoint.component';
import { StartSelectShiftComponent } from './components/centrifuge/start-select-shift/start-select-shift.component';
import { SelectMoveComponent } from './components/weighing/select-move/select-move.component';
import { ConfirmWeighingComponent } from './components/weighing/confirm-weighing/confirm-weighing.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';
import { SelectMoveAddTallowComponent } from './components/centrifuge/select-move-add-tallow/select-move-add-tallow.component';
import { SelectCookerAddTallowComponent } from './components/centrifuge/select-cooker-add-tallow/select-cooker-add-tallow.component';
import { ConfirmAddTallowComponent } from './components/centrifuge/confirm-add-tallow/confirm-add-tallow.component';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, CentrifugeComponent, StartComponent, ConfirmStartComponent, FinishComponent, ConfirmFinishComponent, StartSelectPeriodComponent, AntioxidantStartSelectShiftComponent, AntioxidantEndSelectShiftComponent, AntioxidantEndConfirmComponent, AntioxidantStartConfirmComponent, AntioxidantCheckpointComponent, StartSelectShiftComponent, SelectMoveComponent, ConfirmWeighingComponent, SelectMoveAddTallowComponent, SelectCookerAddTallowComponent, ConfirmAddTallowComponent],
  imports: [
    CommonModule,
    TanksRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class TanksModule { }
