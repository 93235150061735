import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/login/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [':host zxing-scanner::ng-deep video { max-height:11.7em; width:100%; object-fit: none;}']
})
export class LoginComponent implements OnInit {

  title = 'CENTRÍFUGA';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  showSmartCard(userData) {
    this.router.navigate(['/tanques/smartcard'], {state: {userData}});
  }

}
