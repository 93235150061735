import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShakersService } from 'src/app/tanks/services/shakers.service';

@Component({
  selector: 'app-select-move',
  templateUrl: './select-move.component.html',
  styles: ['']
})
export class SelectMoveComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private shakersService: ShakersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.shakersService.getWaitingWeighingMoves().then(resp => {
      this.moves = resp as any;
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/tanques/procesos/centrifuga/pesar/confirmar'], {state: {move}});
  }

}
