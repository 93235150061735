import { Component, OnInit } from '@angular/core';
import { PeriodService } from '../../services/period.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-period-finish',
  templateUrl: './select-period-finish.component.html',
  styles: []
})
export class SelectPeriodFinishComponent implements OnInit {

  periods = [];
  loading = false;

  constructor(
    private periodService: PeriodService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.periodService.getActivePeriods().then(resp => {
      this.periods = resp['data'];
      this.loading = false;
    });
  }

  select(period) {
    this.router.navigate(['/periodos/finalizar/confirmar'], {state: {period}});
  }

}
