<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR CARGA DE ADITIVOS
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
    
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TURNO SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{data.shift.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- REACTOR SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                REACTOR SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{data.reactor.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //REACTOR SELECCIONADO -->
                                        
                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                INICIO DE OPERACIÓN
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" id="" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->
    
                                        <!-- VOLUMEN DE SEBO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                VOLUMEN DE SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="tallowVolume" type="number" class="form-control" id="" placeholder="INGRESAR VOLUMEN EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-area-chart"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //VOLUMEN DE SEBO -->
    
                                        <!-- TEMPERATURA REGISTRADA -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TEMPERATURA REGISTRADA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="temperature" type="number" class="form-control" id="" placeholder="INGRESAR TEMPERATURA EN °C">
                                                <div class="form-control-position">
                                                    <i class="fa fa-thermometer-half"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TEMPERATURA REGISTRADA -->
    
                                        <!-- TONSIL AGREGADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TONSIL AGREGADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="addedTonsil" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-cogs"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- TONSIL AGREGADO -->
    
                                        <!-- CARBON ACTIVADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                CARBÓN ACTIVADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="addedActivatedCarbon" type="number" class="form-control" id="" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-bolt"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //CARBON ACTIVADO -->

                                        <div class="col-sm-12 col-md-12">
                                            
                                            <div class="row">
                                                <div class="col">
                                                    <div class="text-bold-600 font-medium-2 mb-1">
                                                        SELECCIONAR TANQUE DE PROCEDENCIA DEL SEBO
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <button (click)="addDist()" type="button" class="btn float-right btn-relief-info mr-1 waves-effect waves-light">
                                                        <i class="fa fa-plus-circle"></i>
                                                        AÑADIR DISTRIBUCIÓN
                                                    </button>
                                                </div>
                                            </div>
                                            <div formArrayName="dists">
                                                <fieldset *ngFor="let d of form.get('dists')['controls']; let i = index;" [formGroupName]="i" class="form-group position-relative has-icon-left">
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <select formControlName="tank" class="select2 form-control">
                                                                <option [ngValue]="null" selected disabled>Selecciona un tanque...</option>
                                                                <option *ngFor="let t of tanks" [ngValue]="t.tank_id">
                                                                    {{t.name}}</option>
                                                            </select>
                                                            <div class="form-control-position">
                                                                <i class="fa fa-database" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <input formControlName="tallowVolume" type="number" class="form-control"
                                                                placeholder="INGRESAR CANTIDAD DE SEBO">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-tint"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <button (click)="deleteDist(i)" type="button" [disabled]="dists.length === 1" class="btn btn-block btn-relief-warning mr-1 waves-effect waves-light">
                                                                <i class="fa fa-minus-circle"></i>
                                                                ELIMINAR
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>
                            <button [disabled]="loading" (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>