<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">

            <!-- DATOS DE INICIO DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- OPERACIÓN DISPONIBLE -->
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-success text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-success shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-clock-o font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- INICIAR CARGA -->
                                        <h1 class="mb-2 text-white">INICIAR PERÍODO</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['../iniciar', 'turno']" type="button" class="btn btn-lg btn-relief-success mb-2 waves-effect waves-light">CONTINUAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //OPERACIÓN DISPONIBLE -->

                    <!-- OPERACIÓN DISPONIBLE -->
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-danger text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-danger shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-clock-o font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- FINALIZAR DESCARGA -->
                                        <h1 class="mb-2 text-white">TERMINAR PERÍODO</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['../finalizar', 'seleccionar']" type="button" class="btn btn-lg btn-relief-danger mb-2 waves-effect waves-light">CONTINUAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-edit font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- FINALIZAR DESCARGA -->
                                        <h1 class="mb-2 text-white">EDITAR PERÍODO</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['../editar']" type="button" class="btn btn-lg btn-relief-info mb-2 waves-effect waves-light">CONTINUAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-warning text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-warning shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-edit font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- FINALIZAR DESCARGA -->
                                        <h1 class="mb-2 text-white">REABRIR PERÍODO</h1>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button [routerLink]="['../reabrir']" type="button" class="btn btn-lg btn-relief-warning mb-2 waves-effect waves-light">CONTINUAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </div>
    </div>
</div>
