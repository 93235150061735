<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR PRODUCTO SOBRANTE
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- MOLINO SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TURNO SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{shift.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //MOLINO SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HORA DE REGISTRO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" id="" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <div class="col-sm-12 col-md-12">

                                            <div class="row">
                                                <div class="col">
                                                    <div class="text-bold-600 font-medium-2 pt-1">
                                                        SOBRANTES
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <button (click)="addLog()" type="button" class="btn float-right btn-relief-info mr-1 waves-effect waves-light">
                                                        <i class="fa fa-plus-circle"></i>
                                                        AÑADIR SOBRANTE
                                                    </button>
                                                </div>
                                            </div>
                                            <div formArrayName="logs">
                                                <fieldset *ngFor="let d of form.get('logs')['controls']; let i = index;" [formGroupName]="i" class="form-group position-relative has-icon-left">
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="row mb-1">
                                                                <div class="col-sm-12">
                                                                    <select formControlName="hopper" class="select2 form-control">
                                                                        <option [ngValue]="null" selected disabled>Selecciona una tolva...</option>
                                                                        <option *ngFor="let h of hoppers" [ngValue]="h.hopper_id">
                                                                            {{h.name}}</option>
                                                                    </select>
                                                                    <div class="form-control-position">
                                                                        <i class="fa fa-filter" aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-1">
                                                                <div class="col-sm-12">
                                                                    <select formControlName="paste" class="select2 form-control">
                                                                        <option [ngValue]="null" selected disabled>Selecciona un tipo de pasta...</option>
                                                                        <option *ngFor="let t of types" [ngValue]="t.type_id">
                                                                            {{t.full_name}} ({{t.short_name}})</option>
                                                                    </select>
                                                                    <div class="form-control-position">
                                                                        <i class="fa fa-th" aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <input formControlName="amount" type="number" class="form-control"
                                                                placeholder="INGRESAR CANTIDAD DE SOBRANTES">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-balance-scale"></i>
                                                            </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="col-sm-4">
                                                            <button (click)="deleteLog(i)" type="button" [disabled]="logs.length === 1" class="btn btn-block btn-relief-warning mx-auto waves-effect waves-light">
                                                                <i class="fa fa-minus-circle"></i>
                                                                ELIMINAR
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                            <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
