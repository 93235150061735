import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PeriodService} from '../../services/period.service';

@Component({
  selector: 'app-confirm-start',
  templateUrl: './confirm-start.component.html',
  styles: []
})
export class ConfirmStartComponent implements OnInit {

  data: any;
  now = new Date();
  loading = false;

  constructor(
    private router: Router,
    private periodService: PeriodService
  ) {
    if (!history.state.data) {
      router.navigate(['/periodos/checkpoint']);
    }

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.data = history.state.data;
  }

  submit() {
    this.loading = true;
    const body = {
      shift_id: this.data.shift.shift_id,
      product_type_id: this.data.type.type_id,
    };
    this.periodService.startPeriod(body).then(resp => {
      this.router.navigate(['/periodos']);
    });
  }

  cancel() {
    this.router.navigate(['/periodos']);
  }

}
