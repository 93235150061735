<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> Ingresar Peso de Ingreso
            </div>

            <!-- DATOS DE MATERIA PRIMA -->
            <section class="basic-textarea">
                <div class="row">
                    <div class="col-sm-12">
                        <form [formGroup]="form">
                            <div class="card" style="">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- PESO RECOLECTADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                Peso Recolectado (Kg)
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{run.quantity|number}} kg" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-truck"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //PESO RECOLECTADO -->

                                        <!-- PESO TOTAL -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                Peso de Ingreso (Kg)
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="weighedQuantity" type="number" class="form-control" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- PESO TOTAL -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                Peso Prehogor (Kg)
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="prehogor" type="number" class="form-control" placeholder="INGRESAR PESO EN KG.">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //PESO TOTAL -->

                                    </div>
                                </div>
                            </div>
                            <button [swal]="confirmSwal" [disabled]="form.invalid"
                                    class="btn btn-lg btn-block btn-relief-success mb-2 waves-effect waves-light">CONFIRMAR</button>
                        </form>
                    </div>
                </div>
            </section>
            <!-- //DATOS DE CARGA DE PRODUCTO -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
<swal #confirmSwal
      title="Confirmar peso"
      (confirm)="submit()"
      [showCancelButton]="true"
      [focusCancel]="true"
      cancelButtonText="Regresar"
      confirmButtonText="Confirmar">
    <div *swalPortal="swalTargets.content">
        <h3>{{form.value.weighedQuantity|number}} kg.</h3>
        <p>¿Desea confirmar el peso registrado?</p>
    </div>
</swal>
