<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR COCEDOR ACTIVO
            </div>
            <!-- DATOS DE COCEDORESS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- COCEDOR DISPONIBLE -->
                    <div *ngFor="let c of cookers" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-industry font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="mb-2 text-white">{{c.cooker.cooker_key}}</h1>
                                        <h3 class="mb-2 text-white">CON MATERIA PRIMA DE:</h3>
                                        <h4 class="mb-2 text-white">{{c.origins}}</h4>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="select(c)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">SELECCIONAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //COCEDOR DISPONIBLE -->
                </div>
            </section>
            <!-- //DATOS DE COCEDORES DISPONIBLES -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
