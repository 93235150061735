import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CookersService } from 'src/app/cookers/services/cookers.service';

@Component({
  selector: 'app-register-temperature',
  templateUrl: './register-temperature.component.html',
  styles: []
})
export class RegisterTemperatureComponent implements OnInit {

  move: any;
  temperature = new UntypedFormControl(null, [Validators.required]);
  now = new Date();
  loading = false;

  constructor(
    private router: Router,
    private cookersService: CookersService
  ) {
    if (!history.state.move) {
      this.router.navigate(['/cocedores/checkpoint'], {replaceUrl: true});
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  register() {
    if (this.temperature.valid) {
      this.loading = true;
      const body = {
        movement_id: this.move.movement_id,
        temperature: this.temperature.value
      };
      this.cookersService.registerTemperature(body).then(resp => {
        this.router.navigate(['/cocedores/procesos'], {replaceUrl: true});
      });
    }
  }

}
