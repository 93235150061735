import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { PumpsService } from '../../services/pumps.service';
import { MovementService } from '../../services/movement.service';
import { TanksService } from '../../services/tanks.service';
import { DestinationsService } from '../../services/destinations.service';

@Component({
  selector: 'app-confirm-start-filtering',
  templateUrl: './confirm-start-filtering.component.html',
  styles: []
})
export class ConfirmStartFilteringComponent implements OnInit {

  move: any;
  pumps = [];
  destinations = [];
  now = new Date();
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private pumpsService: PumpsService,
    private movementService: MovementService,
    private destinationsService: DestinationsService
  ) {
    if (!history.state.move) {
      router.navigate(['/filtro/procesos']);
    }
    setInterval(() => this.now = new Date(), 1000);
    this.buildForm();
  }

  ngOnInit() {
    this.loading = true;
    this.move = history.state.move;

    const getPumpsProm = this.pumpsService.getInactivePumps();
    const getDestinationsProm = this.destinationsService.getAllDestinations();

    Promise.all([getPumpsProm, getDestinationsProm]).then(response => {
      this.pumps = response[0]['data'];
      this.destinations = response[1]['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      pump: [null, Validators.required],
      pumpPressure: [null, Validators.required],
      destination: [null, Validators.required]
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        pump_id: this.form.value.pump,
        pump_pressure: this.form.value.pumpPressure,
        tallow_destination_id: this.form.value.destination
      };
      this.movementService.startFiltering(this.move.movement_id, body).then(resp => {
        this.router.navigate(['/filtro/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/filtro/procesos']);
  }

}
