import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { UnloadLoginComponent } from './components/unload-login/unload-login.component';
import { UnloadMainMenuComponent } from './components/unload-main-menu/unload-main-menu.component';
import { UnloadDetectedUserComponent } from './components/unload-detected-user/unload-detected-user.component';
import { AuthGuard } from './guards/auth.guard';
import { UnloadDetailComponent } from './components/unload-detail/unload-detail.component';


const routes: Routes = [
  { path: 'descarga', component: UnloadLoginComponent },
  { path: 'descarga', component: MainComponent, children: [
    { path: 'smartcard', component: UnloadDetectedUserComponent, canActivate: [AuthGuard]},
    { path: 'inicio', component: UnloadMainMenuComponent, canActivate: [AuthGuard]},
    { path: 'detalle', component: UnloadDetailComponent, canActivate: [AuthGuard]},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnloadRoutingModule { }
