import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DestinationsService } from '../../../services/destinations.service';
import { ScreenStatesService } from '../../../services/screen-states.service';
import { MagnetChecksService } from '../../../services/magnet-checks.service';

@Component({
  selector: 'app-magnet-check-create-check',
  templateUrl: './magnet-check-create-check.component.html',
  styles: []
})
export class MagnetCheckCreateCheckComponent implements OnInit {

  shift: any;
  now = new Date();
  form: UntypedFormGroup;
  dests = [];
  states = [];
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private destinationsService: DestinationsService,
    private screenStatesService: ScreenStatesService,
    private magnetChecksService: MagnetChecksService
  ) {
    if (!history.state.shift) {
      router.navigate(['/molienda/procesos']);
    }
    this.buildForm();
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.loading = true;

    this.shift = history.state.shift;
    const getDestinationsProm = this.destinationsService.getAllDestinations();
    const getStatesProm = this.screenStatesService.getAllStates();

    Promise.all([getDestinationsProm, getStatesProm]).then(resp => {
      this.dests = resp[0]['data'];
      this.states = resp[1]['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      amount: [null, Validators.required],
      screws: [true],
      wires: [true],
      metals: [true],
      burr: [true],
      destination: [null, Validators.required],
      state: [null, Validators.required],
      remarks: [null],
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        shift_id: this.shift.shift_id,
        amount: this.form.value.amount,
        screws: this.form.value.screws === true ? 1 : 0,
        wires: this.form.value.wires === true ? 1 : 0,
        metals: this.form.value.metals === true ? 1 : 0,
        burr: this.form.value.burr === true ? 1 : 0,
        destination_id: this.form.value.destination,
        screens_state_id: this.form.value.state,
        remarks: this.form.value.remarks,
      };

      this.magnetChecksService.registerCheck(body).then(resp => this.router.navigate(['/molienda/procesos']));

    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

}
