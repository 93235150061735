import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GrindingService } from '../../../services/grinding.service';

@Component({
  selector: 'app-grinding-confirm-start',
  templateUrl: './grinding-confirm-start.component.html',
  styles: []
})
export class GrindingConfirmStartComponent implements OnInit {

  move: any;
  now = new Date();
  lotsWithSalmonix = new UntypedFormControl(null, Validators.required);
  remarks = new UntypedFormControl(null);
  loading = false;

  constructor(
    private router: Router,
    private grindingService: GrindingService
  ) {
    if (!history.state.move) {
      router.navigate(['/molienda/procesos']);
    }

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  submit() {
    if (this.lotsWithSalmonix.valid) {
      this.loading = true;
      const body = {
        period_id: this.move.period_id,
        movement_id: this.move.movement_id,
        lots_with_salmonix: this.lotsWithSalmonix.value,
        remarks: this.remarks.value
      };
      this.grindingService.startMovement(body).then(resp => {
        this.router.navigate(['/molienda/procesos']);
      });

    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

}
