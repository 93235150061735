import { Component, OnInit } from '@angular/core';
import { ShakersService } from '../../../services/shakers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styles: []
})
export class FinishComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private shakersService: ShakersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.shakersService.getAllActiveShakers().then(resp => {
      this.moves = resp as any;
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/tanques/procesos/centrifuga/finalizar/confirmar'], {state: {move}});
  }

}
