import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AntioxidantService } from 'src/app/prehogor/services/antioxidant.service';

@Component({
  selector: 'app-finish-select-shift',
  templateUrl: './finish-select-shift.component.html',
  styles: []
})
export class FinishSelectShiftComponent implements OnInit {

  checks = [];
  loading = false;

  constructor(
    private antioxidantService: AntioxidantService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.antioxidantService.getActiveChecks().then(resp => {
      this.checks = resp['data'];
      this.loading = false;
    });
  }

  select(check) {
    this.router.navigate(['/prehogor/antioxidante/fin/confirmar'], {state: {check}});
  }

}
