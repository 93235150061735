import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WeighService } from 'src/app/weighing/services/weigh.service';
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-departure-weighing',
  templateUrl: './departure-weighing.component.html',
  styles: []
})
export class DepartureWeighingComponent implements OnInit {

  loading = false;
  run = {
    run_id: null,
    first_weight: null,
    quantity: null
  };
  isExternal: boolean;

  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private weighService: WeighService,
    public swalTargets: SwalPortalTargets
  ) {
    if (!history.state.run) {
      router.navigate(['/bascula/salida']);
    }

    this.buildForm();
  }

  ngOnInit() {
    this.run = history.state.run;
    this.isExternal = history.state.isExternal;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      weighedQuantity: [null, Validators.required]
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        weighed_quantity: this.form.value.weighedQuantity
      };

      if (this.isExternal) {
        this.weighService.externalDepartureWeigh(this.run.run_id, body).then(resp => {
          this.router.navigate(['/bascula/inicio'], {replaceUrl: true});
        });
      } else {
        this.weighService.departureWeigh(this.run.run_id, body).then(resp => {
          this.router.navigate(['/bascula/inicio'], {replaceUrl: true});
        });
      }
    }
  }

}
