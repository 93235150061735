import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { CentrifugeComponent } from './components/centrifuge/centrifuge.component';
import { StartComponent } from './components/centrifuge/start/start.component';
import { ConfirmStartComponent } from './components/centrifuge/confirm-start/confirm-start.component';
import { FinishComponent } from './components/centrifuge/finish/finish.component';
import { ConfirmFinishComponent } from './components/centrifuge/confirm-finish/confirm-finish.component';
import { AuthGuard } from '../weighing/guards/auth.guard';
import { AntioxidantCheckpointComponent } from './components/antioxidant/antioxidant-checkpoint/antioxidant-checkpoint.component';
import { AntioxidantStartSelectShiftComponent } from './components/antioxidant/antioxidant-start-select-shift/antioxidant-start-select-shift.component';
import { AntioxidantEndSelectShiftComponent } from './components/antioxidant/antioxidant-end-select-shift/antioxidant-end-select-shift.component';
import { AntioxidantStartConfirmComponent } from './components/antioxidant/antioxidant-start-confirm/antioxidant-start-confirm.component';
import { AntioxidantEndConfirmComponent } from './components/antioxidant/antioxidant-end-confirm/antioxidant-end-confirm.component';
import { StartSelectShiftComponent } from './components/centrifuge/start-select-shift/start-select-shift.component';
import { SelectMoveComponent } from './components/weighing/select-move/select-move.component';
import { ConfirmWeighingComponent } from './components/weighing/confirm-weighing/confirm-weighing.component';
import {SelectMoveAddTallowComponent} from './components/centrifuge/select-move-add-tallow/select-move-add-tallow.component';
import {SelectCookerAddTallowComponent} from './components/centrifuge/select-cooker-add-tallow/select-cooker-add-tallow.component';
import {ConfirmAddTallowComponent} from './components/centrifuge/confirm-add-tallow/confirm-add-tallow.component';


const routes: Routes = [
  { path: 'tanques', component: LoginComponent },
  { path: 'tanques', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard]},
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga', component: CentrifugeComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/iniciar/turno', component: StartSelectShiftComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/iniciar/tanque', component: StartComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/iniciar/confirmar', component: ConfirmStartComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/agregar/proceso', component: SelectMoveAddTallowComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/agregar/cocedor', component: SelectCookerAddTallowComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/agregar/confirmar', component: ConfirmAddTallowComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/finalizar/proceso', component: FinishComponent, canActivate: [AuthGuard]},
    { path: 'procesos/centrifuga/finalizar/confirmar', component: ConfirmFinishComponent, canActivate: [AuthGuard] },
    { path: 'procesos/centrifuga/pesar/proceso', component: SelectMoveComponent, canActivate: [AuthGuard] },
    { path: 'procesos/centrifuga/pesar/confirmar', component: ConfirmWeighingComponent, canActivate: [AuthGuard] },
    { path: 'procesos/antioxidante', component: AntioxidantCheckpointComponent, canActivate: [AuthGuard]},
    { path: 'procesos/antioxidante/inicio', component: AntioxidantStartSelectShiftComponent, canActivate: [AuthGuard]},
    { path: 'procesos/antioxidante/inicio/confirmar', component: AntioxidantStartConfirmComponent, canActivate: [AuthGuard]},
    { path: 'procesos/antioxidante/fin', component: AntioxidantEndSelectShiftComponent, canActivate: [AuthGuard]},
    { path: 'procesos/antioxidante/fin/confirmar', component: AntioxidantEndConfirmComponent, canActivate: [AuthGuard]},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TanksRoutingModule { }
