<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <!-- RESULTADO DE SCANNER -->
            <section class="basic-textarea">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card text-white bg-gradient-success text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-success shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-truck font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <h1 class="mb-2 text-white">Unidad Autorizada<span *ngIf="isExternal"> (Externa)</span></h1>
                                        <!-- ID UNIDAD -->
                                        <p class="card-text">{{vehicle.vehicle_key}}</p>
                                        <!-- PLACAS -->
                                        <p class="card-text">{{vehicle.license_plate}}</p>
                                        <!-- MARCA -->
                                        <p class="card-text">{{vehicle.brand}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button (click)="weigh()" type="button" class="btn btn-lg btn-block btn-relief-success mr-1 mb-2 waves-effect waves-light">INGRESAR PESO</button>
                        <button (click)="back()" type="button" class="btn btn-lg btn-block btn-relief-warning mr-1 mb-2 waves-effect waves-light">CANCELAR</button>
                    </div>
                </div>
            </section>
            <!-- //RESULTADO DE SCANNER -->
        </div>
    </div>
</div>