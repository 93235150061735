import { Component, OnInit } from '@angular/core';
import {PeriodService} from '../../services/period.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-edit-period-select-period',
  templateUrl: './edit-period-select-period.component.html',
  styles: [
  ]
})
export class EditPeriodSelectPeriodComponent implements OnInit {
    periods = [];
    loading = false;

    constructor(
        private periodService: PeriodService,
        private router: Router
    ) { }

    ngOnInit() {
        this.loading = true;
        this.periodService.getActivePeriods().then(resp => {
            this.periods = resp['data'];
            this.loading = false;
        });
    }

    onSelectPeriod(period) {
        this.router.navigate(['/periodos/editar/confirmar'], {state: {period}});
    }

}
