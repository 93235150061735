import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MovementService } from '../../services/movement.service';
import { TanksService } from '../../services/tanks.service';

@Component({
  selector: 'app-register-additives',
  templateUrl: './register-additives.component.html',
  styles: []
})
export class RegisterAdditivesComponent implements OnInit {

  data: any;
  now = new Date();
  form: UntypedFormGroup;
  dists: UntypedFormArray;
  tanks = [];
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private movementService: MovementService,
    private tanksService: TanksService
  ) {
    if (!history.state.data) {
      router.navigate(['/filtro/procesos']);
    }
    setInterval(() => this.now = new Date(), 1000);
    this.buildForm();
  }

  ngOnInit() {
    this.data = history.state.data;
    this.loading = true;
    this.tanksService.getAllTanks().then(resp => {
      this.tanks = resp['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      tallowVolume: [null, Validators.required],
      temperature: [null, Validators.required],
      addedTonsil: [null, Validators.required],
      addedActivatedCarbon: [null, Validators.required],
      dists: this.formBuilder.array([this.createDist()])
    });

    this.dists = this.form.get('dists') as UntypedFormArray;
  }

  createDist() {
    return this.formBuilder.group({
      tank: [null, Validators.required],
      tallowVolume: [null, Validators.required]
    });
  }

  addDist() {
    this.dists = this.form.get('dists') as UntypedFormArray;
    this.dists.push(this.createDist());
  }

  deleteDist(i) {
    this.dists = this.form.get('dists') as UntypedFormArray;
    if (this.dists.length > 1) {
      this.dists.removeAt(i);
    }
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;

      const dists = [];
      for (let i = 0; i < this.dists.length; i++) {
        const d = this.dists.at(i);
        dists.push({
          tank_id: d.value.tank,
          tallow_volume: d.value.tallowVolume
        });
      }

      const body = {
        shift_id: this.data.shift.shift_id,
        reactor_id: this.data.reactor.tank_id,
        tallow_volume: this.form.value.tallowVolume,
        temperature: this.form.value.temperature,
        added_tonsil: this.form.value.addedTonsil,
        added_activated_carbon: this.form.value.addedActivatedCarbon,
        tallow_moves: JSON.stringify(dists)
      };

      this.movementService.startMovement(body).then(resp => {
        this.router.navigate(['/filtro/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/filtro/procesos']);
  }

}
