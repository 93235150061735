import { Component, OnInit } from '@angular/core';
import { WorkShiftsService } from 'src/app/production/services/work-shifts.service';
import { Router } from '@angular/router';
import { PeriodService } from '../../../../period/services/period.service';
import {ExpellersService} from '../../../../expellers/services/expellers.service';

@Component({
  selector: 'app-grinding-select-shift',
  templateUrl: './grinding-select-shift.component.html',
  styles: []
})
export class GrindingSelectShiftComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
      private expellersService: ExpellersService,
      private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.expellersService.getAllFinished().then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/molienda/procesos/molienda/iniciar/confirmar'], {state: {move}});
  }

}
