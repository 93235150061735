import { Component, OnInit } from '@angular/core';
import { WorkShiftsService } from 'src/app/production/services/work-shifts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-warehouse-checkpoint',
  templateUrl: './warehouse-checkpoint.component.html',
  styles: []
})
export class WarehouseCheckpointComponent implements OnInit {

  shifts = [];
  loading = false;
  constructor(
    private workShiftsService: WorkShiftsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.workShiftsService.getAll().then(resp => {
      this.shifts = resp['data'];
      this.loading = false;
    });
  }

  select(shift) {
    this.router.navigate(['/molienda/procesos/bodegas/registrar'], {state: {shift}});
  }

}
