import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrindingRoutingModule } from './grinding-routing.module';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdditivesStartSelectShiftComponent } from './components/additives/additives-start-select-shift/additives-start-select-shift.component';
import { AdditivesEndSelectShiftComponent } from './components/additives/additives-end-select-shift/additives-end-select-shift.component';
import { AdditivesEndConfirmComponent } from './components/additives/additives-end-confirm/additives-end-confirm.component';
import { AdditivesStartConfirmComponent } from './components/additives/additives-start-confirm/additives-start-confirm.component';
import { AdditivesCheckpointComponent } from './components/additives/additives-checkpoint/additives-checkpoint.component';
import { GrindingCheckpointComponent } from './components/grinding/grinding-checkpoint/grinding-checkpoint.component';
import { GrindingSelectShiftComponent } from './components/grinding/grinding-select-shift/grinding-select-shift.component';
import { GrindingSelectGrinderComponent } from './components/grinding/grinding-select-grinder/grinding-select-grinder.component';
import { GrindingSelectPasteComponent } from './components/grinding/grinding-select-paste/grinding-select-paste.component';
import { GrindingConfirmStartComponent } from './components/grinding/grinding-confirm-start/grinding-confirm-start.component';
import { GrindingSelectGrinderFinishComponent } from './components/grinding/grinding-select-grinder-finish/grinding-select-grinder-finish.component';
import { GrindingConfirmFinishComponent } from './components/grinding/grinding-confirm-finish/grinding-confirm-finish.component';
import { LeftoversCheckpointComponent } from './components/leftovers/leftovers-checkpoint/leftovers-checkpoint.component';
import { LeftoversRegisterComponent } from './components/leftovers/leftovers-register/leftovers-register.component';
import { WarehouseConfirmCheckComponent } from './components/warehouse/warehouse-confirm-check/warehouse-confirm-check.component';
import { WarehouseCheckpointComponent } from './components/warehouse/warehouse-checkpoint/warehouse-checkpoint.component';
import { MagnetCheckCheckpointComponent } from './components/magnet-check/magnet-check-checkpoint/magnet-check-checkpoint.component';
import { MagnetCheckSelectShiftComponent } from './components/magnet-check/magnet-check-select-shift/magnet-check-select-shift.component';
import { MagnetCheckCreateCheckComponent } from './components/magnet-check/magnet-check-create-check/magnet-check-create-check.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, AdditivesStartSelectShiftComponent, AdditivesEndSelectShiftComponent, AdditivesEndConfirmComponent, AdditivesStartConfirmComponent, AdditivesCheckpointComponent, GrindingCheckpointComponent, GrindingSelectShiftComponent, GrindingSelectGrinderComponent, GrindingSelectPasteComponent, GrindingConfirmStartComponent, GrindingSelectGrinderFinishComponent, GrindingConfirmFinishComponent, LeftoversCheckpointComponent, LeftoversRegisterComponent, WarehouseConfirmCheckComponent, WarehouseCheckpointComponent, MagnetCheckCheckpointComponent, MagnetCheckSelectShiftComponent, MagnetCheckCreateCheckComponent, ],
  imports: [
    CommonModule,
    GrindingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class GrindingModule { }
