<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> REGISTRAR INDICADORES
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- MOLINO SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TURNO SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{shift.name}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //MOLINO SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HORA DE REGISTRO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" id=""
                                                    value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <div class="col-sm-12 col-md-12">

                                            <div class="row">
                                                <div class="col">
                                                    <div class="text-bold-600 font-medium-2 pt-1">
                                                        INDICADORES
                                                    </div>
                                                </div>
                                            </div>
                                            <div formArrayName="checks">
                                                <fieldset *ngFor="let w of warehouses; let i = index;"
                                                    [formGroupName]="i"
                                                    class="form-group position-relative has-icon-left">
                                                    <hr>
                                                    <h6>{{w.name}}</h6>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <input formControlName="temperature" type="number"
                                                                        class="form-control"
                                                                        placeholder="INGRESAR TEMPERATURA (ºC)">
                                                                    <div class="form-control-position">
                                                                        <i class="fa fa-thermometer-half"></i>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-sm-6">

                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <input formControlName="humidity" type="number"
                                                                        class="form-control"
                                                                        placeholder="INGRESAR HUMEDAD RELATIVA (%)">
                                                                    <div class="form-control-position">
                                                                        <i class="fa fa-tachometer"></i>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="form.invalid"
                                class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                            <button (click)="cancel()" type="button"
                                class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
