import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ShakersService} from '../../../services/shakers.service';

@Component({
  selector: 'app-confirm-finish',
  templateUrl: './confirm-finish.component.html',
  styles: []
})
export class ConfirmFinishComponent implements OnInit {

  now = new Date();
  move: any;
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private shakersService: ShakersService
  ) {
    if (!history.state.move) {
      router.navigate(['/tanques/procesos']);
    }
    this.buildForm();
    setInterval(() => this.now = new Date());
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      remarks: [null]
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = {
        movement_id: this.move.movement_id,
        remarks: this.form.value.remarks
      };

      this.shakersService.finishProcess(body).then(resp => {
        this.router.navigate(['/tanques/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/tanques/procesos']);
  }

}
