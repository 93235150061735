import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovementService } from '../../services/movement.service';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TanksService } from '../../services/tanks.service';

@Component({
  selector: 'app-confirm-finish-filtering',
  templateUrl: './confirm-finish-filtering.component.html',
  styles: []
})
export class ConfirmFinishFilteringComponent implements OnInit {

  move: any;
  now = new Date();
  tanks = [];
  dists: UntypedFormArray;
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private router: Router,
    private movementService: MovementService,
    private formBuilder: UntypedFormBuilder,
    private tanksService: TanksService
  ) {
    if (!history.state.move) {
      router.navigate(['/filtro/procesos']);
    }

    this.buildForm();

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.loading = true;
    this.move = history.state.move;
    this.tanksService.getAllTanks().then(resp => {
      this.tanks = resp['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      dists: this.formBuilder.array([this.createDist()])
    });

    this.dists = this.form.get('dists') as UntypedFormArray;
  }

  createDist() {
    return this.formBuilder.group({
      tank: [null, Validators.required],
      tallowVolume: [null, Validators.required]
    });
  }

  addDist() {
    this.dists = this.form.get('dists') as UntypedFormArray;
    this.dists.push(this.createDist());
  }

  deleteDist(i) {
    this.dists = this.form.get('dists') as UntypedFormArray;
    if (this.dists.length > 1) {
      this.dists.removeAt(i);
    }
  }

  submit() {

    if (this.form.valid) {
      this.loading = true;
      const dists = [];
      for (let i = 0; i < this.dists.length; i++) {
        const d = this.dists.at(i);
        dists.push({
          tank_id: d.value.tank,
          tallow_volume: d.value.tallowVolume
        });
      }

      const body = {
        tallow_moves: JSON.stringify(dists)
      };

      this.movementService.finishFiltering(this.move.movement_id, body).then(resp => {
        this.router.navigate(['/filtro/procesos']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/filtro/procesos']);
  }

}
