import { Component, OnInit } from '@angular/core';
import { WeighService } from '../../services/weigh.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styles: []
})
export class EntryComponent implements OnInit {

  runs = {
    enteringInternalVehicles: [],
    enteringExternalVehicles: []
  };
  loading = false;

  constructor(
    private weighService: WeighService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.weighService.getEnteringVehicles().then(resp => {
      this.runs = resp as any;
      this.loading = false;
    });
  }

  details(r: any, isExt = false) {
    const run = {
      run_id: r.run_id,
      quantity: isExt ? r.reported_quantity : r.scanner_quantity,
      vehicle: {
        vehicle_key: isExt ? r.vehicle_key : r.vehicle.vehicle_key,
        license_plate: isExt ? r.license_plate : r.vehicle.license_plate,
        brand: isExt ? r.brand : r.vehicle.brand,
      }
    };
    this.router.navigate([r.run_id], { relativeTo: this.route, state: {run, isExternal: isExt} });
  }

}
