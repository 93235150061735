import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovementService } from '../../services/movement.service';

@Component({
  selector: 'app-confirm-finish-shaking',
  templateUrl: './confirm-finish-shaking.component.html',
  styles: []
})
export class ConfirmFinishShakingComponent implements OnInit {

  move: any;
  now = new Date();
  loading = false;

  constructor(
    private router: Router,
    private movementService: MovementService
  ) {
    if (!history.state.move) {
      router.navigate(['/filtro/procesos']);
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  confirm() {
    this.loading = true;
    this.movementService.finishShaking(this.move.movement_id).then(resp => {
      this.router.navigate(['/filtro/procesos']);
    });
  }

  cancel() {
    this.router.navigate(['/filtro/procesos']);
  }

}
