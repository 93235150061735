import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shaking',
  templateUrl: './shaking.component.html',
  styles: []
})
export class ShakingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
