import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { ExpellersLoginComponent } from './components/expellers-login/expellers-login.component';
import { ExpellersDetectedUserComponent } from './components/expellers-detected-user/expellers-detected-user.component';
import { ExpellersProcessesComponent } from './components/expellers-processes/expellers-processes.component';
import { ExpellersCheckpointComponent } from './components/expellers-checkpoint/expellers-checkpoint.component';
import { SelectAvailableComponent } from './components/select-available/select-available.component';
import { StartProcessComponent } from './components/start-process/start-process.component';
import { AmperageSelectActiveExpellerComponent } from './components/amperage-select-active-expeller/amperage-select-active-expeller.component';
import { AmperageComponent } from './components/amperage/amperage.component';
import { FinishProcessSelectExpellerComponent } from './components/finish-process-select-expeller/finish-process-select-expeller.component';
import { FinishProcessComponent } from './components/finish-process/finish-process.component';
import { MagnetsCheckpointComponent } from './components/magnets/checkpoint/checkpoint.component';
import { MagnetsProductTypeComponent } from './components/magnets/magnets-product-type/magnets-product-type.component';
import { MagnetsExpellerComponent } from './components/magnets/magnets-expeller/magnets-expeller.component';
import { MagnetsSelectComponent } from './components/magnets/magnets-select/magnets-select.component';
import { RegisterCleaningComponent } from './components/magnets/register-cleaning/register-cleaning.component';
import { StartSelectPeriodComponent } from './components/start-select-period/start-select-period.component';
import { AddSelectExpellerComponent } from './components/add-select-expeller/add-select-expeller.component';
import { ExpellersSelectCookerComponent } from './components/expellers-select-cooker/expellers-select-cooker.component';
import { AddCookerConfirmComponent } from './components/add-cooker-confirm/add-cooker-confirm.component';
import { AuthGuard } from '../login/guards/auth.guard';


const routes: Routes = [
  { path: 'expellers', component: ExpellersLoginComponent },
  { path: 'expellers', component: MainComponent, children: [
    { path: 'smartcard', component: ExpellersDetectedUserComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ExpellersProcessesComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller', component: ExpellersCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/periodo', component: StartSelectPeriodComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/iniciar', component: StartProcessComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/agregar/seleccionar', component: AddSelectExpellerComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/agregar/cocedor', component: ExpellersSelectCookerComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/agregar/confirmar', component: AddCookerConfirmComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/amperaje/seleccionar', component: AmperageSelectActiveExpellerComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/amperaje/cocedor', component: SelectAvailableComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/amperaje/registrar', component: AmperageComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/finalizar/seleccionar', component: FinishProcessSelectExpellerComponent, canActivate: [AuthGuard] },
    { path: 'procesos/expeller/finalizar/confirmar', component: FinishProcessComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes', component: MagnetsCheckpointComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/periodo', component: MagnetsProductTypeComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/expeller', component: MagnetsExpellerComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/seleccionar', component: MagnetsSelectComponent, canActivate: [AuthGuard] },
    { path: 'procesos/imanes/registrar', component: RegisterCleaningComponent, canActivate: [AuthGuard] },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpellersRoutingModule { }
