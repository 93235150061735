import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductTypesService } from '../../../../production/services/product-types.service';

@Component({
  selector: 'app-grinding-select-paste',
  templateUrl: './grinding-select-paste.component.html',
  styles: []
})
export class GrindingSelectPasteComponent implements OnInit {

  types = [];
  obj: any;

  constructor(
    private router: Router,
    private productTypesService: ProductTypesService
  ) {
    if (!history.state.data) {
      router.navigate(['/molienda/procesos']);
    }
  }

  ngOnInit() {
    this.obj = history.state.data;
    this.productTypesService.getAll().then(resp => {
      this.types = resp as any;
    });
  }

  select(type) {
    this.obj.type = type;
    this.router.navigate(['/molienda/procesos/molienda/iniciar/confirmar'], {state: {data: this.obj}});
  }

}
