import { Component, OnInit } from '@angular/core';
import {PeriodService} from '../../services/period.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-last-closed-periods',
  templateUrl: './last-closed-periods.component.html',
  styles: [
  ]
})
export class LastClosedPeriodsComponent implements OnInit {
    periods = [];
    loading = false;

    constructor(
        private periodService: PeriodService,
        private router: Router
    ) { }

    ngOnInit() {
        this.loading = true;
        this.periodService.getClosedPeriods().then(resp => {
            this.periods = resp['data'];
            this.loading = false;
        });
    }

    onSelectPeriod(period) {
        this.router.navigate(['/periodos/reabrir/confirmar'], {state: {period}});
    }

}
