import { Component, OnInit } from '@angular/core';
import { MovementService } from '../../services/movement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finish-shaking',
  templateUrl: './finish-shaking.component.html',
  styles: []
})
export class FinishShakingComponent implements OnInit {

  moves: any;
  loading = false;

  constructor(
    private movementService: MovementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.movementService.getShakingMovements().then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/filtro/procesos/agitacion/terminar/confirmar'], {state: {move}});
  }

}
