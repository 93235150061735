import { Component, OnInit } from '@angular/core';
import { MovementService } from '../../services/movement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-shaking',
  templateUrl: './start-shaking.component.html',
  styles: []
})
export class StartShakingComponent implements OnInit {

  moves = [];
  loading = false;

  constructor(
    private movementService: MovementService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.movementService.getWaitingShakingMovements().then(resp => {
      this.moves = resp['data'];
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/filtro/procesos/agitacion/iniciar/confirmar'], {state: {move}});
  }

}
