import { Component, OnInit } from '@angular/core';
import { PeriodService } from 'src/app/period/services/period.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-select-period',
  templateUrl: './start-select-period.component.html',
  styles: []
})
export class StartSelectPeriodComponent implements OnInit {

  periods = [];
  loading = false;

  constructor(
    private periodService: PeriodService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.periodService.getActivePeriods().then(resp => {
      this.periods = resp['data'];
      this.loading = false;
    });
  }

  start(period) {
    const data = {
      period
    };
    this.router.navigate(['/expellers/procesos/expeller/iniciar'], {state: {data}});
  }

}
