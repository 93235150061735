import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login-main',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  @Input() areaTitle: string;
  @Input() isExpeller ? = false;
  @Output() loginSuccess = new EventEmitter<any>();

  loading = false;
  nip = new UntypedFormControl(null, Validators.required);
  formSubmitAttempt: boolean;
  wrongData: boolean;
  serverError: boolean;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  submit() {
    if (this.nip.valid) {
      this.loading = true;
      this.authService.signInByHash(this.nip.value, this.isExpeller).then(resp => {
        const userData = {
          name: resp['full_name'],
        };
        this.loginSuccess.emit(userData);
      }).catch(err => {
        if (err.status === 401 || err.status === 404) {
          this.wrongData = true;
          this.serverError = false;
        } else {
          this.serverError = true;
          this.wrongData = false;
        }
        this.loading = false;
      });
    }
  }

}
