import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrehogorRoutingModule } from './prehogor-routing.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { SelectCookerComponent } from './components/select-cooker/select-cooker.component';
import { SelectTypeComponent } from './components/select-type/select-type.component';
import { StartComponent } from './components/start/start.component';
import { FinishCookerComponent } from './components/finish-cooker/finish-cooker.component';
import { FinishComponent } from './components/finish/finish.component';
import { SelectOriginComponent } from './components/select-origin/select-origin.component';
import { AntioxidantComponent } from './components/antioxidant/antioxidant.component';
import { StartSelectShiftComponent } from './components/antioxidant/start-select-shift/start-select-shift.component';
import { StartConfirmComponent } from './components/antioxidant/start-confirm/start-confirm.component';
import { FinishSelectShiftComponent } from './components/antioxidant/finish-select-shift/finish-select-shift.component';
import { FinishConfirmComponent } from './components/antioxidant/finish-confirm/finish-confirm.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';

@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, CheckpointComponent, SelectCookerComponent, SelectTypeComponent, StartComponent, FinishCookerComponent, FinishComponent, SelectOriginComponent, AntioxidantComponent, StartSelectShiftComponent, StartConfirmComponent, FinishSelectShiftComponent, FinishConfirmComponent],
  imports: [
    CommonModule,
    PrehogorRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class PrehogorModule { }
