import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartcard',
  templateUrl: './smartcard.component.html',
  styles: []
})
export class SmartcardComponent implements OnInit {

  userData = {
    name: '',
  };

  constructor(
    private router: Router
  ) {
    if (!history.state.userData) {
      router.navigate(['/cocedores'], {replaceUrl: true});
    }
  }

  ngOnInit() {
    this.userData = history.state.userData;
  }

  onNavigate(navigate: boolean) {
    if (navigate) {
      this.router.navigate(['/cocedores/procesos'], {replaceUrl: true});
    } else {
      this.router.navigate(['/cocedores'], {replaceUrl: true});
    }
  }

}
