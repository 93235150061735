import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expellers-login',
  templateUrl: './expellers-login.component.html',
  styles: [':host zxing-scanner::ng-deep video { max-height:11.7em; width:100%; object-fit: none;}']
})
export class ExpellersLoginComponent implements OnInit {

  title = 'EXPELLERS';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  showSmartCard(userData) {
    this.router.navigate(['/expellers/smartcard'], {state: {userData}});
  }
}
