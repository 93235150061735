<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR FIN DE OPERACIÓN
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- AGITADOR SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                AGITADOR SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{move.reactor.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //AGITADOR SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                FIN DE OPERACIÓN
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                VOLUMEN TOTAL DE SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.tallow_volume|number}} KG."
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                % DE A.G.L.
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.agl|number}} %"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                AMARILLOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.yellow|number}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                ROJOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{move.red|number}}"
                                                    disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                DESTINO DEL SEBO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                       value="{{move.destination?.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-money"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">

                                            <div class="row">
                                                <div class="col">
                                                    <div class="text-bold-600 font-medium-2 mb-1">
                                                        DISTRIBUCIÓN DEL SEBO
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <button (click)="addDist()" type="button"
                                                        class="btn float-right btn-relief-info mr-1 waves-effect waves-light">
                                                        <i class="fa fa-plus-circle"></i>
                                                        AÑADIR DISTRIBUCIÓN
                                                    </button>
                                                </div>
                                            </div>
                                            <div formArrayName="dists">
                                                <fieldset
                                                    *ngFor="let d of form.get('dists')['controls']; let i = index;"
                                                    [formGroupName]="i"
                                                    class="form-group position-relative has-icon-left">
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <select formControlName="tank" class="select2 form-control">
                                                                <option [ngValue]="null" selected disabled>Selecciona un
                                                                    tanque...</option>
                                                                <option *ngFor="let t of tanks" [ngValue]="t.tank_id">
                                                                    {{t.name}}</option>
                                                            </select>
                                                            <div class="form-control-position">
                                                                <i class="fa fa-database" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <input formControlName="tallowVolume" type="number"
                                                                class="form-control"
                                                                placeholder="INGRESAR CANTIDAD DE SEBO">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-tint"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <button (click)="deleteDist(i)" type="button"
                                                                [disabled]="dists.length === 1"
                                                                class="btn btn-block btn-relief-warning mr-1 waves-effect waves-light">
                                                                <i class="fa fa-minus-circle"></i>
                                                                ELIMINAR
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="form.invalid"
                                class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>
                            <button (click)="cancel()" type="button"
                                class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
