import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpellersRoutingModule } from './expellers-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExpellersLoginComponent } from './components/expellers-login/expellers-login.component';
import { ExpellersDetectedUserComponent } from './components/expellers-detected-user/expellers-detected-user.component';
import { ExpellersProcessesComponent } from './components/expellers-processes/expellers-processes.component';
import { ExpellersCheckpointComponent } from './components/expellers-checkpoint/expellers-checkpoint.component';
import { ExpellersProductTypeSelectComponent } from './components/expellers-product-type-select/expellers-product-type-select.component';
import { ExpellersSelectCookerComponent } from './components/expellers-select-cooker/expellers-select-cooker.component';
import { SelectAvailableComponent } from './components/select-available/select-available.component';
import { StartProcessComponent } from './components/start-process/start-process.component';
import { AmperageSelectActiveExpellerComponent } from './components/amperage-select-active-expeller/amperage-select-active-expeller.component';
import { AmperageComponent } from './components/amperage/amperage.component';
import { FinishProcessComponent } from './components/finish-process/finish-process.component';
import { FinishProcessSelectExpellerComponent } from './components/finish-process-select-expeller/finish-process-select-expeller.component';
import { MagnetsCheckpointComponent } from './components/magnets/checkpoint/checkpoint.component';
import { MagnetsProductTypeComponent } from './components/magnets/magnets-product-type/magnets-product-type.component';
import { MagnetsExpellerComponent } from './components/magnets/magnets-expeller/magnets-expeller.component';
import { MagnetsSelectComponent } from './components/magnets/magnets-select/magnets-select.component';
import { RegisterCleaningComponent } from './components/magnets/register-cleaning/register-cleaning.component';
import { StartSelectPeriodComponent } from './components/start-select-period/start-select-period.component';
import { AddSelectExpellerComponent } from './components/add-select-expeller/add-select-expeller.component';
import { AddCookerConfirmComponent } from './components/add-cooker-confirm/add-cooker-confirm.component';
import { LoginModule } from '../login/login.module';
import {NgxLoadingModule} from 'ngx-loading';


@NgModule({
  declarations: [ExpellersLoginComponent, ExpellersDetectedUserComponent, ExpellersProcessesComponent, ExpellersCheckpointComponent, ExpellersProductTypeSelectComponent, ExpellersSelectCookerComponent, SelectAvailableComponent, StartProcessComponent, AmperageSelectActiveExpellerComponent, AmperageComponent, FinishProcessComponent, FinishProcessSelectExpellerComponent, MagnetsCheckpointComponent, MagnetsProductTypeComponent, MagnetsExpellerComponent, MagnetsSelectComponent, RegisterCleaningComponent, StartSelectPeriodComponent, AddSelectExpellerComponent, AddCookerConfirmComponent],
  imports: [
    CommonModule,
    ExpellersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    NgxLoadingModule
  ]
})
export class ExpellersModule { }
