import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {PeriodService} from '../../services/period.service';

@Component({
  selector: 'app-reopen-period',
  templateUrl: './reopen-period.component.html',
  styles: [
  ]
})
export class ReopenPeriodComponent implements OnInit {

    loading = false;
    period: any;

    constructor(
        private router: Router,
        private periodService: PeriodService,
    ) {
        if (!history.state.period) {
            router.navigate(['/periodos/checkpoint']);
        }
    }

    ngOnInit() {
        this.period = history.state.period;
    }

    submit() {
        this.loading = true;

        this.periodService.reopenPeriod(this.period.period_id).then(() => {
            this.router.navigate(['/periodos']);
        });
    }

    cancel() {
        this.router.navigate(['/periodos']);
    }

}
