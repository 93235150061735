import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookersService } from '../../services/cookers.service';

@Component({
  selector: 'app-cooker-pressure-register',
  templateUrl: './cooker-pressure-register.component.html',
  styles: []
})
export class CookerPressureRegisterComponent implements OnInit {

  move: any;
  pressure = new UntypedFormControl(null, [Validators.required]);
  now = new Date();
  loading = false;

  constructor(
    private router: Router,
    private cookersService: CookersService
  ) {
    if (!history.state.move) {
      this.router.navigate(['/cocedores/checkpoint']);
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
  }

  register() {
    if (this.pressure.valid) {
      this.loading = true;
      const body = {
        movement_id: this.move.movement_id,
        pressure: this.pressure.value
      };
      this.cookersService.registerPressure(body).then(resp => {
        this.router.navigate(['/cocedores/procesos']);
      });
    }
  }

}
