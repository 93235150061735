import {Component, OnInit} from '@angular/core';
import {MovementService} from '../../services/movement.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-rework-select-move',
    templateUrl: './rework-select-move.component.html',
    styles: []
})
export class ReworkSelectMoveComponent implements OnInit {

    loading = false;
    moves = [];

    constructor(
        private movementsService: MovementService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.movementsService.getRejectedMovements().then(resp => {
            this.moves = resp['data'];
            this.loading = false;
        });
    }

    selectMove(move: any) {
        this.router.navigate(['/filtro/procesos/retrabajo/confirmar'], {state: {move}});
    }

}
