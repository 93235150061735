import { Component, OnInit } from '@angular/core';
import { WeighService } from '../../services/weigh.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-departure',
  templateUrl: './departure.component.html',
  styles: []
})
export class DepartureComponent implements OnInit {

  runs = {
    internal: [],
    external: []
  };
  loading = false;

  constructor(
    private weighService: WeighService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.weighService.getDepartingVehicles().then(resp => {
      this.runs = resp as any;
      this.loading = false;
    });
  }

  details(run, isExternal = false) {
    const r = {
      run_id: run.run_id,
      first_weight: run.first_weight,
      quantity: isExternal ? run.reported_quantity : run.scanner_quantity,
      vehicle: {
        vehicle_key: isExternal ? run.vehicle_key : run.vehicle.vehicle_key,
        license_plate: isExternal ? run.license_plate : run.vehicle.license_plate,
        brand: isExternal ? run.brand : run.vehicle.brand
      }
    };

    this.router.navigate([run.run_id], { relativeTo: this.route, state: {run: r, isExternal} });
  }

}
