import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ShakersService} from '../../../services/shakers.service';

@Component({
    selector: 'app-confirm-add-tallow',
    templateUrl: './confirm-add-tallow.component.html',
    styles: []
})
export class ConfirmAddTallowComponent implements OnInit {

    now = new Date();
    data: any;
    loading = false;

    constructor(
        private router: Router,
        private shakersService: ShakersService
    ) {
        if (!history.state.data) {
            router.navigate(['/tanques/procesos']);
        }
        setInterval(() => this.now = new Date());
    }

    ngOnInit() {
        this.data = history.state.data;
    }

    submit() {
        this.loading = true;
        const body = {
            shakerMovementId: this.data.move.movement_id,
        };

        this.shakersService.assignShakerMove(this.data.cooker.movement_id, body).then(resp => {
            this.router.navigate(['/tanques/procesos']);
        });
    }

    cancel() {
        this.router.navigate(['/tanques/procesos']);
    }

}
