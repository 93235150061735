<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> CONFIRMAR LIMPIEZA DE IMANES
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                        <!-- MOLINO SELECCIONADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TURNO SELECCIONADO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{shift.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-pie-chart"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //MOLINO SELECCIONADO -->

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HORA DE LIMPIEZA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control" value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                CANTIDAD RETIRADA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input formControlName="amount" type="number" class="form-control" placeholder="INGRESA LA CANTIDAD DE MATERIAL RETIRADO (KG)">
                                                <div class="form-control-position">
                                                    <i class="fa fa-balance-scale"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <!-- MATERIAL RETIRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2">
                                                MATERIAL RETIRADO
                                            </div>
                                            <div class="card-body">
                                                <ul class="list-unstyled mb-0">
                                                    <li class="d-inline-block mr-2">
                                                        <fieldset>
                                                            <div class="vs-checkbox-con vs-checkbox-primary">
                                                                <input formControlName="screws" type="checkbox" >
                                                                <span class="vs-checkbox">
                                                                    <span class="vs-checkbox--check">
                                                                        <i class="vs-icon feather icon-check"></i>
                                                                    </span>
                                                                </span>
                                                                <span class="">Tornillos</span>
                                                            </div>
                                                        </fieldset>
                                                    </li>
                                                    <li class="d-inline-block mr-2">
                                                        <fieldset>
                                                            <div class="vs-checkbox-con vs-checkbox-primary">
                                                                <input formControlName="wires" type="checkbox"  >
                                                                <span class="vs-checkbox">
                                                                    <span class="vs-checkbox--check">
                                                                        <i class="vs-icon feather icon-check"></i>
                                                                    </span>
                                                                </span>
                                                                <span class="">Alambres</span>
                                                            </div>
                                                        </fieldset>
                                                    </li>
                                                    <li class="d-inline-block mr-2">
                                                        <fieldset>
                                                            <div class="vs-checkbox-con vs-checkbox-primary">
                                                                <input formControlName="metals" type="checkbox"  >
                                                                <span class="vs-checkbox">
                                                                    <span class="vs-checkbox--check">
                                                                        <i class="vs-icon feather icon-check"></i>
                                                                    </span>
                                                                </span>
                                                                <span class="">Metales</span>
                                                            </div>
                                                        </fieldset>
                                                    </li>
                                                    <li class="d-inline-block mr-2">
                                                        <fieldset>
                                                            <div class="vs-checkbox-con vs-checkbox-primary">
                                                                <input formControlName="burr" type="checkbox" >
                                                                <span class="vs-checkbox">
                                                                    <span class="vs-checkbox--check">
                                                                        <i class="vs-icon feather icon-check"></i>
                                                                    </span>
                                                                </span>
                                                                <span class="">Rebabas</span>
                                                            </div>
                                                        </fieldset>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- //MATERIAL RETIRADO -->


                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                DESTINO DEL MATERIAL
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <select formControlName="destination" class="select2 form-control">
                                                    <option [ngValue]="null" selected disabled>Selecciona un destino...</option>
                                                    <option *ngFor="let d of dests" [ngValue]="d.destination_id">{{d.name}}</option>
                                                </select>
                                                <div class="form-control-position">
                                                    <i class="fa fa-braille"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <!-- REVISIÓN DE CRIVAS -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                ESTADO DE CRIVAS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <select formControlName="state" class="select2 form-control">
                                                    <option [ngValue]="null" selected disabled>Selecciona una opción...</option>
                                                    <option *ngFor="let s of states" [ngValue]="s.state_id">{{s.name}}</option>
                                                </select>
                                                <div class="form-control-position">
                                                    <i class="fa fa-braille"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //REVISIÓN DE CRIVAS -->

                                        <!-- OBSERVACIONES -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                OBSERVACIONES
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <textarea formControlName="remarks" type="text" class="form-control" rows="8" id="" placeholder="INGRESA TUS OBSERVACIONES"></textarea>
                                                <div class="form-control-position">
                                                    <i class="fa fa-search"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //OBSERVACIONES -->

                                    </div>
                                </div>
                            </div>
                            <button [disabled]="form.invalid" type="submit" class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>

                            <button (click)="cancel()" type="button" class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
