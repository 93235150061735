<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> Mostrando Unidades en Espera
            </div>
            <!-- DATOS DE RUTAS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- RUTA DISPONIBLE -->
                    <div *ngFor="let i of runs.enteringInternalVehicles" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-truck font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="text-white">{{i.route.route_key}}</h1>
                                        <!-- ID DE UNIDAD -->
                                        <p class="card-text">{{i.vehicle.vehicle_key}}</p>
                                        <!-- OPERADOR DE LA UNIDAD -->
                                        <p class="card-text">{{i.operator.username}}</p>
                                        <!-- TIEMPO DE ESPERA EN PLANTA-->
                                        <p class="card-text">{{i.waitingTime|number}} min.</p>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="details(i)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">AUTORIZAR ACCESO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let e of runs.enteringExternalVehicles" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-truck font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- TERRIRORIO ASIGNADO DE RUTA -->
                                        <h1 class="mb-2 text-white">{{e.origin_city}} (Externo)</h1>
                                        <!-- ID DE UNIDAD -->
                                        <p class="card-text">{{e.vehicle_key}}</p>
                                        <!-- OPERADOR DE LA UNIDAD -->
                                        <p class="card-text">{{e.operator}}</p>
                                        <!-- TIEMPO DE ESPERA EN PLANTA-->
                                        <p class="card-text">{{e.waitingTime|number}} min.</p>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="details(e, true)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">AUTORIZAR ACCESO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //RUTA DISPONIBLE -->

                </div>
            </section>
            <!-- //DATOS DE RUTA DISPONIBLE -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
