import { Component, OnInit } from '@angular/core';
import { CookersService } from '../../services/cookers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cooker-pressure-select-active',
  templateUrl: './cooker-pressure-select-active.component.html',
  styles: []
})
export class CookerPressureSelectActiveComponent implements OnInit {

  moves: any;
  loading = false;

  constructor(
    private cookersService: CookersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.cookersService.getAllStartedProcesses().then(resp => {
      this.moves = resp as any;
      this.loading = false;
    });
  }

  select(move) {
    this.router.navigate(['/cocedores', 'presion', 'confirmar'], {state: {move}});
  }

}
