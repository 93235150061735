import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CookersService {

  private url = environment.apiUrl + 'cookers';
  private token = '';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.token = auth.getToken();
    this.headers = new HttpHeaders({ Authorization: this.token });
  }

  getAllStandBy() {
    const headers = this.headers;
    const url = this.url + `/standby`;
    return this.http.get(url, { headers })
    .toPromise();
  }

  getWaitingForStartMoves(periodID: any) {
    const headers = this.headers;
    const url = this.url + `/waiting/${periodID}`;
    return this.http.get(url, { headers })
    .toPromise();
  }

  getFinishedMoves(periodID: any) {
    const headers = this.headers;
    const url = this.url + `/finished/${periodID}`;
    return this.http.get(url, { headers })
    .toPromise();
  }

  getMovesByExpellerMoveId(moveId: any) {
        const headers = this.headers;
        const url = this.url + `/expellers/${moveId}`;
        return this.http.get(url, { headers })
            .toPromise();
    }

  getAllActive() {
    const headers = this.headers;
    const url = this.url + `/active`;
    return this.http.get(url, { headers })
    .toPromise();
  }

  startProcess(moveID: any, body: any) {
    const headers = this.headers;
    const url = this.url + `/start/${moveID}`;
    return this.http.post(url, body, { headers })
    .toPromise();
  }

  getAllStartedProcesses(group = false) {
    const params = {};
    if (group) {
        params['group'] = 1;
    }
    const headers = this.headers;
    const url = this.url + `/started`;
    return this.http.get(url, { headers, params })
    .toPromise();
  }

  registerTemperature(body) {
    const headers = this.headers;
    const url = this.url + `/temperature`;
    return this.http.post(url, body, { headers })
    .toPromise();
  }

  registerPressure(body) {
    const headers = this.headers;
    const url = this.url + `/pressure`;
    return this.http.post(url, body, { headers })
    .toPromise();
  }

  finishProcess(body, moveID: any) {
    const headers = this.headers;
    const url = this.url + `/finish/${moveID}`;
    return this.http.put(url, body, { headers })
    .toPromise();
  }
}
