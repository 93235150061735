import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PeriodService } from '../../../period/services/period.service';

@Component({
  selector: 'app-select-type',
  templateUrl: './select-type.component.html',
  styles: []
})
export class SelectTypeComponent implements OnInit {

  periods = [];
  data: any;
  loading = false;

  constructor(
    private periodService: PeriodService,
    private router: Router
  ) {
    if (!history.state.data) {
      router.navigate(['/prehogor/procesos']);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.data = history.state.data;
    this.periodService.getActivePeriods().then(resp => {
      this.periods = resp['data'];
      this.loading = false;
    });
  }

  start(period) {
    this.data.period = period;
    this.router.navigate(['/prehogor', 'iniciar', 'cocedor'], {state: {data: this.data}});
  }

}
