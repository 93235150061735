import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookersService } from '../../services/cookers.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-confirm-finish',
  templateUrl: './confirm-finish.component.html',
  styles: []
})
export class ConfirmFinishComponent implements OnInit {

  move: any;
  now = new Date();
  remarks = new UntypedFormControl(null);
  loading = false;
  product = '';
  cookers = '';

  constructor(
    private router: Router,
    private cookersService: CookersService
  ) {
    if (!history.state.move) {
      router.navigate(['/cocedores/checkpoint']);
    }
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit() {
    this.move = history.state.move;
    if (this.move?.multiple) {
        this.product = this.move.product;
        this.cookers = this.move.cookers;
    } else {
        this.product = this.move.period.product.full_name + ' (' + this.move.period.product.short_name + ')';
        this.cookers = this.move.cooker.cooker_key;
    }
  }

  finishProcess() {
    if (this.remarks.valid) {
      this.loading = true;
      const body = {
        remarks: this.remarks.value,
      };

      if (this.move?.multiple) {
          body['movements'] = this.move.movements;
      }

      const id = this.move?.multiple ? 0 : this.move.movement_id;

      this.cookersService.finishProcess(body, id).then(resp => {
        this.router.navigate(['/cocedores/procesos']);
      });
    }
  }

}
